import AppStoreBadge from '../AppStoreBadge';
import badge from './badge.svg';

const AppleAppStoreBadge = (): JSX.Element => {
  return (
    <AppStoreBadge
      targetUrl='https://apps.apple.com/ca/app/mypeekaville/id1560170962'
      imageUrl={badge}
      altText='Download from App Store'
    />
  );
};

export default AppleAppStoreBadge;
