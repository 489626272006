export const navItems = [
  {
    name: 'SEL Solutions',
    subItems: [
      { name: 'Elementary School (PreK-5)', route: '/elementary' },
      { name: 'Secondary School (6-12)', route: '/secondary' },
      { name: 'Professional Development', route: '/professionalDevelopment' },
      { name: 'Print Book Sets', route: 'https://shop.peekapak.com' },
    ],
  },
  { name: "Who It's For", route: '/whoItsFor' },
  {
    name: 'Research',
    subItems: [
      { name: 'Elementary Program Research', route: '/researchElementary' },
      {
        name: 'Middle School Program Research',
        route: '/researchMiddleSchool',
      },
      { name: 'High School Program Research', route: '/researchHighSchool' },
      { name: 'Design Principles', route: '/designPrinciples' },
      { name: 'What is SEL', route: '/whatIsSEL' },
    ],
  },
  { name: 'Our Impact', route: '/successStories' },
  {
    name: 'Resources',
    subItems: [
      { name: 'Funding Sources', route: '/funding' },
      { name: 'Tech Specs', route: '/techSpecs' },
      { name: 'Webinars', route: '/webinars' },
      { name: 'Blog', route: 'https://blog.peekapak.com' },
      { name: 'FAQs', route: 'https://help.peekapak.com' },
    ],
  },
  {
    name: 'About Us',
    subItems: [
      { name: 'Our Team', route: '/about' },
      { name: 'Careers', route: '/careers' },
      { name: 'Press', route: '/press' },
      { name: 'Privacy', route: '/privacyPolicy' },
      {
        name: 'Contact Us',
        route: '/contact',
      },
    ],
  },
  { name: 'Login', route: '/login' },
];
