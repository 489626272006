import { useEffect, useState } from 'react';
import cx from 'classnames';
import { SpinnerOverlay } from '../SpinnerOverlay';
import styles from '../SCSS/SlideShow.module.scss';

import { getMiddleSchoolModule } from '../BackendInterface';
import { useHistory, useParams } from 'react-router-dom';
import { Module, SlideDeck } from '../../peekapak-types/LessonPlanTypes';

const MiddleSchoolSlideShow = () => {
  const [thisModule, setModule] = useState<Module | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const { id, grade, language, contentId } = useParams();

  const getSlides = async () => {
    setIsLoading(true);
    try {
      const slideModule = await getMiddleSchoolModule(id, language, grade);
      setModule(slideModule.data);
    } catch (error) {
      console.info(`error is ${JSON.stringify(error, null, 2)}`);
      throw new Error('Error fetching slides');
    } finally {
      setIsLoading(false);
      setIsLoaded(true);
    }
  };

  const onIframeLoaded = () => {
    // console.debug('%ciframeloaded...', 'background: blue, color: yellow');
    setIsLoading(false);
  };

  // if contentId is provided, then we should look through the studentContents
  // collection and find the matching content to obtain the URL
  // otherwise, we should just use the first slideDeck content
  const slideShowUrl = (() => {
    if (!isLoaded || !thisModule) return '';

    if (contentId) {
      for (const studentContentObject of thisModule.studentContent) {
        for (const contentObject of studentContentObject.content) {
          if (contentObject.type === 'slideDeck' && studentContentObject.id === contentId) {
            return (contentObject as SlideDeck).uri;
          }
        }
      }
      throw new Error(`Specified SlideDeck content not found in studentContent ${contentId}`);
    } else {
      for (const studentContentObject of thisModule.studentContent) {
        for (const contentObject of studentContentObject.content) {
          if (contentObject.type === 'slideDeck') {
            return (contentObject as SlideDeck).uri;
          }
        }
      }
      throw new Error('No slideDeck content found in studentContent, when it was expected');
    }
  })();

  useEffect(() => {
    !isLoaded && getSlides();
  }, [getSlides, thisModule]);

  // console.debug(`%cSlideShow render`, 'background: blue; color: yellow');
  return (
    <div className={styles.slideShowContainer}>
      <SpinnerOverlay isShow={isLoading} />
      <div className={styles.goBack} onClick={() => history.go(-1)}>
        <i className='fa fa-chevron-left'></i>
      </div>
      <iframe
        title='SlideShow'
        src={slideShowUrl}
        frameBorder='0'
        width='960'
        height='629'
        allowFullScreen={true}
        onLoad={() => onIframeLoaded()}
        className={cx({
          [`${styles.hidden}`]: isLoading,
        })}
      ></iframe>
    </div>
  );
};

export default MiddleSchoolSlideShow;
