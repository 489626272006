import StatePagesTemplate from './StatePagesTemplate';
import TestimonialsSegment from '../anonymous/TestimonialsSegment';
import { texasFaq } from './expandableFAQContents';
import tulare from '../images/logos/logo-tulare.png';
import sheldon from '../images/logos/logo-sheldonisd.png';
import blueJay from '../images/logos/logo-blue-jay.png';
import tulsa from '../images/logos/logo-tulsa.png';

const taxasPageData = {
  perfectMatchOneTitle: 'Partnering to Build a Positive Learning Environment',
  perfectMatchOneSubtitle: `As the educator of your school, you want all students to succeed, but you’re seeing diverse learning needs, teacher burnout, increasing state mandates, and a lack of coping skills.`,
  perfectMatchTwoTitle: 'Why Peekapak and Texas are a Perfect Match',
  whyChoosePeekapakLists: [
    'Fulfills Texas’s SEL Mandates & TEKS ELA Standards',
    'Offers Spanish versions to reach your English Learners',
    'Aligns to CASEL Standards',
    'Engages students through game-based learning',
  ],
  schoolLogos: [tulare, sheldon, blueJay, tulsa],
};
const TexasPage = () => {
  return (
    <StatePagesTemplate
      state='Texas'
      stateShorthand="TEKS's"
      perfectMatchOneTitle={taxasPageData.perfectMatchOneTitle}
      perfectMatchOneSubtitle={taxasPageData.perfectMatchOneSubtitle}
      perfectMatchTwoTitle={taxasPageData.perfectMatchTwoTitle}
      whyChoosePeekapakLists={taxasPageData.whyChoosePeekapakLists}
      schoolLogos={taxasPageData.schoolLogos}
      stateSpecificFaq={texasFaq}
    >
      <TestimonialsSegment />
    </StatePagesTemplate>
  );
};

export default TexasPage;
