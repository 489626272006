import styles from './ToDoText.module.scss';
import { InlineIcon } from '@iconify/react';
import layersOutline from '@iconify/icons-eva/layers-outline';

interface Props {
  data: { title: string; content: string }[];
  hasAssets: boolean;
  goToLastTab: () => void;
}

const ToDoText = ({ data, hasAssets, goToLastTab }: Props): JSX.Element => {
  return (
    <>
      {data.map((textObject, index) => {
        return (
          <div key={`textBlock ${index}`} className={styles.container}>
            {textObject.title && <h3>{textObject.title}</h3>}
            <div
              className={styles.textBlock}
              dangerouslySetInnerHTML={{
                __html: textObject.content,
              }}
            />
            {index === 0 && hasAssets && (
              <div className={styles.assetLinks} onClick={goToLastTab}>
                <InlineIcon icon={layersOutline} />
                &nbsp;Review Class Materials
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default ToDoText;
