import CaliforniaPage from '../../StatePages/CaliforniaPage';
import OklahomaPage from '../../StatePages/OklahomaPage';
import TexasPage from '../../StatePages/TexasPage';
import GenericLanding from '../GenericLanding';
import UtahLanding from '../UtahLanding';
import UAELanding from '../UAELanding';

interface LandingPageParams {
  [country: string]: RegionParam | Param;
}

interface RegionParam {
  [region: string]: Param;
}

export interface Param {
  [param: string]: string;
}

interface LandingPageComponent {
  variant: string | null;
  page: JSX.Element;
}

export const landingPageParams: LandingPageParams = {
  'United States': {
    Utah: {
      param: '?variant=100',
    },
    California: {
      param: '?variant=110',
    },
    Texas: {
      param: '?variant=120',
    },
    Oklahoma: {
      param: '?variant=130',
    },
  },
  UAE: {
    param: '?variant=200',
  },
  'United Arab Emirates': {
    param: '?variant=200',
  },
};

export const landingPageComponents: LandingPageComponent[] = [
  {
    variant: null,
    page: <GenericLanding />,
  },
  {
    variant: '100',
    page: <UtahLanding />,
  },
  {
    variant: '110',
    page: <CaliforniaPage />,
  },
  {
    variant: '120',
    page: <TexasPage />,
  },
  {
    variant: '130',
    page: <OklahomaPage />,
  },
  {
    variant: '200',
    page: <UAELanding />,
  },
];
