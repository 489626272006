import combineClasses from 'classnames';
import styles from './LinkDiv.module.scss';

interface Props {
  uppercase?: boolean;
  bold?: boolean;
  grey?: boolean;
  black?: boolean;
  blue?: boolean;
  darkGrey?: boolean;
  children: React.ReactNode;
  className: string;
  onClick?: () => void;
}

const LinkDiv = ({
  uppercase,
  bold,
  grey,
  black,
  blue,
  darkGrey,
  children,
  className,
  ...otherProps
}: Props): JSX.Element => (
  <div
    className={combineClasses({
      [`${styles.linkDiv}`]: true,
      [`${styles.uppercase}`]: uppercase,
      [`${styles.bold}`]: bold,
      [`${styles.grey}`]: grey,
      [`${styles.black}`]: black,
      [`${styles.blue}`]: blue,
      [`${styles.darkGrey}`]: darkGrey,
      [`${styles.underlineHover}`]: true,
      className,
    })}
    {...otherProps}
  >
    {children}
  </div>
);

export default LinkDiv;
