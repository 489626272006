import { ReactNode } from 'react';
import Modal from 'react-bootstrap/Modal';
import PrimaryButton from '../PrimaryButton';
import { Icon } from '@iconify/react';
import closeIcon from '@iconify/icons-material-symbols/close-rounded';
import styles from './ConfirmationModal.module.scss';

interface Props {
  cancelText: string | null;
  confirmText: string | null;
  handleHide: () => void;
  handlePerformAction: () => void;
  show: boolean;
  subtitle: string | ReactNode;
  title: string | ReactNode;
}

const ConfirmationModal = ({
  cancelText,
  confirmText,
  handleHide,
  handlePerformAction,
  show,
  subtitle,
  title,
}: Props) => {
  const handleConfirm = async () => {
    try {
      handlePerformAction();
    } catch {
      console.error('Could not complete action');
    } finally {
      handleHide();
    }
  };

  return (
    <Modal centered show={show} className={styles.exitConfirmation}>
      <div className={styles.cancelButtonContainer} onClick={handleHide}>
        <Icon className={styles.cancelButton} icon={closeIcon} />
      </div>
      <div className={styles.texts}>
        <p>
          <strong>{title}</strong>
        </p>
        <p>{subtitle}</p>
      </div>
      <div className={styles.buttons}>
        {cancelText && (
          <PrimaryButton small alertSecondary onClick={handleHide}>
            {cancelText}
          </PrimaryButton>
        )}
        {confirmText && (
          <PrimaryButton small onClick={handleConfirm}>
            {confirmText}
          </PrimaryButton>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
