import reformed from 'react-reformed';
import { Form } from 'react-bootstrap';
import { Segment, VerticalSpacer } from './core/core';
import PrimaryButton from './components/PrimaryButton/PrimaryButton';
import utilityStyles from './SCSS/common/utilityStyles.module.scss';
import styles from './SCSS/Gallery.module.scss';
import segmentStyles from './SCSS/Segment.module.scss';

function FieldGroup({ id, label, help, ...props }) {
  return (
    <Form.Group controlId={id}>
      <Form.Label>{label}</Form.Label>
      <Form.Control {...props} />
      {help && <Form.Text>{help}</Form.Text>}
    </Form.Group>
  );
}

const _CreateNewSchool = (props) => {
  function onChangeValue(event: Event) {
    props.setProperty(event.target.name, event.target.value);
  }

  function onSubmit(e) {
    e.preventDefault();
    props.onSubmit(props.model);
  }

  return (
    <div className={styles.gallery}>
      <VerticalSpacer ems={2} />
      <Segment className={utilityStyles.line}>
        <div className={styles.titleBar}>
          <div className={segmentStyles.moduleTitle}>School Editor</div>
        </div>
      </Segment>
      <div className={styles.form}>
        <form>
          <FieldGroup
            id='formControlsDistrict'
            name='district'
            type='text'
            value={props.model.district}
            onChange={onChangeValue}
            placeholder='Unique District Name'
          />
          <FieldGroup
            id='formControlsSchoolName'
            name='name'
            type='text'
            value={props.model.name}
            onChange={onChangeValue}
            placeholder='School Name'
          />
          <FieldGroup
            id='formControlsAddress'
            name='address'
            type='text'
            value={props.model.address}
            onChange={onChangeValue}
            placeholder='School Address'
          />
          <FieldGroup
            id='formControlsAddress'
            name='city'
            type='text'
            value={props.model.city}
            onChange={onChangeValue}
            placeholder='School City'
          />
          <FieldGroup
            id='formControlsSchoolState'
            name='state'
            type='text'
            value={props.model.state}
            onChange={onChangeValue}
            placeholder='School State'
          />
          <FieldGroup
            id='formControlsSchoolCountry'
            name='country'
            type='text'
            value={props.model.country}
            onChange={onChangeValue}
            placeholder='School Country'
          />
          <PrimaryButton onClick={onSubmit} type='submit'>
            Submit
          </PrimaryButton>
        </form>
      </div>
    </div>
  );
};

export default reformed()(_CreateNewSchool);
