import { getPublicMediaRoot } from '../../serviceAgent';
import styles from './ToDoImage.module.scss';

interface Props {
  data: { uri: string; title: string }[];
}

const ToDoImage = ({ data }: Props): JSX.Element => {
  return (
    <>
      {data?.map((image, index: number) => {
        const imgPath = `${getPublicMediaRoot()}images/secondary/${image.uri}`;
        return (
          <div key={`${image.title}${index}`} className={styles.container}>
            <h3>{image.title}</h3>
            <img src={imgPath} alt={image.title} />
          </div>
        );
      })}
    </>
  );
};

export default ToDoImage;
