import { useEffect, useRef, useState } from 'react';
import ReactGesture from 'react-gesture';
import MediaQuery from 'react-responsive';
import cx from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getCountryOriginBasedOnIp, mod, noWhiteSpace } from '../GlobalFunctions';
import SectionHeader from '../components/SectionHeader';
import styles from '../SCSS/TestimonialsSegment.module.scss';
import tulsa from '../images/logos/logo-tulsa.png';
import dps from '../images/logo-dps.png';
import longBranch from '../images/logos/logo-longbranch.svg';
import southBronx from '../images/logo-bronx.png';
import kippStl from '../images/logos/logo-kippstl.png';
import sheldonIsd from '../images/logo-sheldonisd.svg';
import barstow from '../images/logo-busd.png';
import jerseyCity from '../images/logo-jerseycity.png';
import chicago from '../images/logos/logo-chicago.png';
import woodridge from '../images/logo-woodridge.png';
import hillside from '../images/logo-hillside.png';
import eps from '../images/logo-eps.png';
import tulare from '../images/logos/logo-tulare.png';
import verona from '../images/logo-verona.png';
import toronto from '../images/logo-tdsb.png';
import sunnybrook from '../images/logo-sunnybrook.png';
import rock from '../images/logo-rock.png';
import dufferinPeel from '../images/logo-dufferin-peel.png';
import windsorEssex from '../images/logo-windsor-essex.png';
import trilliumLakelands from '../images/logo-trillium-lakelands.png';
import avonMaitland from '../images/logo-avon.png';
import durham from '../images/logo-durham-catholic.png';
import educatorLogoBBN from '../images/UAELanding/bright-beginnings-nursery.png';
import educatorLogoAGS from '../images/UAELanding/ags-grammer.png';
import educatorLogoTaaleem from '../images/UAELanding/taaleem.png';

const TRANSITION_DURATION = 400;
const quotes = [
  {
    quotation: noWhiteSpace`“I enjoyed the lessons and I used each one. They were
    just what my students needed to help them process what was happening with
    the COVID-19 pandemic. I feel that they got to know Cody well and it was a
    nice switch at the end to meet Ines (because they had seen her room in one
    of the lessons). This was an extremely useful resource and I thank you for
    the lessons.”`,
    attribution: 'Rosetta S, Teacher, The Bronx, NY',
  },
  {
    quotation: noWhiteSpace`“I started Peekapak with my small group of
    third-graders last week and they absolutely LOVE it. They're really grown to
    admire the characters and can relate. Prior to my school closing due to
    COVID-19, I provided direct reading intervention to my students who are
    mostly ELLs (16 third-graders total). We did 5 minutes of SEL (i.e.
    mindfulness practice, mood meter, etc) every day so my students are
    connecting to the Peekapak program with ease.”`,
    attribution: 'Sharrece O, Education Success Foundation',
  },
  {
    quotation: noWhiteSpace`“The lessons have been very motivating to the
    students using it.  It is so cute- sometimes when one of the students is
    having a hard time, my assistant or I will give them one of the character
    cards (whichever character they think might help them) and they take it back
    to class as "extra support" to help them remember to use their strategies
    and make good choices... kind of silly- but it works!”`,
    attribution: 'Ginnette N, Fond du Lac School District',
  },
  {
    quotation: noWhiteSpace`“Peekapak has helped students express their feelings
    in a productive manner  and Peekapak has helped students become more aware
    of how others are feeling around them.”`,
    attribution: 'KIPP St. Louis',
  },
  {
    quotation: noWhiteSpace`“My scholars LOVE Peekapak! Before we even start our
    mindful minute, many students are already getting into position and take
    deep breaths on their own. It has taught our kids about how to identify
    their feelings and I've seen that skill being used throughout our school
    day.”`,
    attribution: 'KIPP St. Louis',
  },
  {
    quotation: noWhiteSpace`“I love Peekapak. The stories, the characters – just
    how everything is there for the teachers. For me, it works out because I do
    it once a week and I can fit it into my language arts.”`,
    attribution: 'Diana Rivera, Kindergarten, Dr. Albert Einstein Academy',
  },
  {
    quotation: noWhiteSpace`“With today’s testing, we are asking children to
    think and work at such a high level. The fact that (Peekapak) is aligned (to
    education standards) eases teachers’ worries.”`,
    attribution: 'Maria Labrador Principal, Elizabeth Public Schools (NJ)',
  },
  {
    quotation: noWhiteSpace`“Seeing teachers speak so enthusiastically about
    what Peekapak has done in their classrooms ... it’s hard to say ‘no’ to
    something like that.”`,
    attribution: 'Robert McNulty, Director of Technology, Rahway Public Schools (NJ)',
  },
];
const UAEQuotes = [
  {
    quotation: noWhiteSpace`“The students responded well to our discussion around what self-regulation means, they made good connections to what we do in our classroom.”`,
    attribution: 'Leona McAuley, Kindergarten Teacher, Abu Dhabi Grammar School, UAE',
  },
  {
    quotation: noWhiteSpace`“The children have really embraced the program, and I can honestly say they've loved every minute of it.”`,
    attribution: 'Gillian Bowie, Bright Beginnings Director, UAE',
  },
  {
    quotation: noWhiteSpace`“I would say immediately with the boys, it has changed their behavior, their interactions with students.”`,
    attribution: 'Anthony Howe, Deputy Head of Pastoral Boys, Al Qeyam Charter School.',
  },
  {
    quotation: noWhiteSpace`“I believe personally while using the app, it gives me a chance to talk about what I'm feeling because I feel stressed most of the time because with schools and exams coming up - Peekapak gives me the opportunity to talk about it.”`,
    attribution: 'Grade 8 Student, Al Qeyam Charter School',
  },
  {
    quotation: noWhiteSpace`“At first when we started using [Peekapak], I didn't really think that it's going to be this important - but it improved my students way of how they show and express their feelings, even I know that they have become more empathetic towards each other.”`,
    attribution: 'Ms. Rana Sherif, English Teacher,, Al Qeyam Charter School',
  },
  {
    quotation: noWhiteSpace`“After a few lessons, some of the teachers are already commenting on how kids are making connections in conversations and the things they've been talking about in the health curriculum.”`,
    attribution: 'Ms. Kim Jackson, Vice Principal at Abu Dhabi Grammar School',
  },
  {
    quotation: noWhiteSpace`“I love using Peekapak in my class! [Students] asked me every single day, can we please do Peekapak today? Especially the dancing games or anything interactive.”`,
    attribution: 'Ms. Gemma, Bright Beginnings Teacher, Abu Dhabi, UAE',
  },
  {
    quotation: noWhiteSpace`“We've seen less bullying, we've seen less fights. We've seen a great change in the manner in which they address each other.  So from my understanding what I've noticed, there's been a big change in my advisory class.”`,
    attribution: 'Mr. Waheed Ebrahim Omar, Academic English Teacher, Al Qeyam Charter School',
  },
  {
    quotation: noWhiteSpace`“Now it is provided and translated in Arabic, there has been more and more understanding and development inside of the kids, understanding both in their two languages. They have improved in their behaviors, it has been from day one, there has been a noticeable change in a good way.”`,
    attribution:
      'Mr Neil Jensen Gulifardo, Science Teacher & Whole School Improvement Data Analysis, Al Qeyam Charter School',
  },
  {
    quotation: noWhiteSpace`“It's more like, it's like we've known each other, you know, like it's like more comfortable and like you can rely on each other and it's you know, it's like a safe environment for us. If we feel like we have any troubles, we can just reach out to one of our classmates and we can just talk about like problems we have at home or school problems with each other.”`,
    attribution: 'Grade 8 Students,  Al Qeyam Charter School',
  },
];
const canadianQuotes = [
  {
    quotation: noWhiteSpace`“Peekapak is one of the best resources I’ve found so far for SEL! It really is a complete package, from the engaging stories with a lesson, to the interactive gameplay, and the at home connections. Teachers, students, and parents will benefit greatly from using this program. The ongoing support and updates from the Peekapak team made it that much more valuable.”`,
    attribution: 'J. Harrison, Mountain View Academy, Calgary',
  },
  {
    quotation: noWhiteSpace`“With the kids at home I've been looking for a range
    of online tools and this one truly checks the box for social emotional
    learning. And best of all, the kids love it! The Peekaville game like
    simulation draws them in, while learning all about respect, gratitude,
    self-regulation and more. Thank you Peekapak team for sharing your resources
    during this challenging time.”`,
    attribution: 'Patrizia Kohly, Toronto, Ontario',
  },
  {
    quotation: noWhiteSpace`“We introduced the books to our classes in a shared reading format. We found that the students really liked learning about the different characters and how the characters reappeared in each book. We really liked how the lesson for each story related to the kindergarten and primary curriculum, touching on personal character, kindness and inclusion. Our students were also choosing to look at these books during free time, which was great to see.  We look forward to using all of the books next year too!”`,
    attribution: 'Leslie M (Kindergarten) & Kelly P (gr. 1), Victory Public School, Guelph, Ontario',
  },
];
export const logos = [
  {
    name: 'Tulsa Public Schools',
    url: tulsa,
  },
  {
    name: 'Denver Public Schools',
    url: dps,
  },
  {
    name: 'Long Branch Public Schools',
    url: longBranch,
  },
  {
    name: 'Catholic School Region Northwest/South Bronx',
    url: southBronx,
  },
  {
    name: 'KIPP St Louis',
    url: kippStl,
  },
  {
    name: 'Sheldon ISD',
    url: sheldonIsd,
  },
  {
    name: 'Barstow School District',
    url: barstow,
  },
  {
    name: 'Jersey City Public Schools',
    url: jerseyCity,
  },
  {
    name: 'Chicago Public Schools',
    url: chicago,
  },
  {
    name: 'Woodridge 68',
    url: woodridge,
  },
  {
    name: 'Hillside Public Schools',
    url: hillside,
  },
  {
    name: 'Elizabeth Public Schools',
    url: eps,
  },
  {
    name: 'Tulare County',
    url: tulare,
  },
  {
    name: 'Verona Public Schools',
    url: verona,
  },
];

export const UAELogos = [
  {
    name: 'AGS Grammer School',
    url: educatorLogoAGS,
  },
  {
    name: 'Bright Beginnings Nursery',
    url: educatorLogoBBN,
  },
  {
    name: 'Taaleem',
    url: educatorLogoTaaleem,
  },
  {
    name: 'Taaleem',
    url: educatorLogoTaaleem,
  },
  {
    name: 'Taaleem',
    url: educatorLogoTaaleem,
  },
  {
    name: 'AGS Grammer School',
    url: educatorLogoAGS,
  },
  {
    name: 'Bright Beginnings Nursery',
    url: educatorLogoBBN,
  },
  {
    name: 'Taaleem',
    url: educatorLogoTaaleem,
  },
  {
    name: 'Taaleem',
    url: educatorLogoTaaleem,
  },
  {
    name: 'Taaleem',
    url: educatorLogoTaaleem,
  },
];

export const canadianLogos = [
  { name: 'Durham Catholic District School Board', url: durham },
  { name: 'Windsor Essex Catholic District School Board', url: windsorEssex },
  { name: 'Dufferin-Peel Catholic District School Board', url: dufferinPeel },
  { name: 'Avon Maitland District School Board', url: avonMaitland },
  { name: 'KIPP St Louis', url: kippStl },
  { name: 'Sheldon ISD', url: sheldonIsd },
  { name: 'Barstow School District', url: barstow },
  { name: 'Tulsa Public Schools', url: tulsa },
  { name: 'Denver Public Schools', url: dps },
  { name: 'Long Branch Public Schools', url: longBranch },
  { name: 'Reach Out Centre For Kids', url: rock },
  { name: 'Sunnybrook School', url: sunnybrook },
  { name: 'Toronto District School Board', url: toronto },
  { name: 'Trillium Lakelands District School Board', url: trilliumLakelands },
];

interface QuotesWheelProps {
  direction: string;
  activeQuote: number;
  onLeftChevron: () => void;
  onRightChevron: () => void;
  displayQuotes: { quotation: string; attribution: string }[];
}

interface QuoteArrowProps {
  right?: boolean;
  onClick?: () => void;
}

interface LogosWheelProps {
  activeLogo: number;
  direction: string;
  steps: string;
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
  onSetActiveLogo: (index: number) => void;
  displayLogos: { name: string; url: string }[];
  showActiveLogoOnly?: boolean;
}

interface ReorderedProps {
  url: string;
  name: string;
}

interface DotProps {
  key?: string;
  active?: boolean;
}

interface TestimonialsSegmentProps {
  whiteBackground?: boolean | undefined;
  isUae?: boolean;
  showActiveLogoOnly?: boolean;
}

const QuotesWheel = ({ direction, activeQuote, onLeftChevron, onRightChevron, displayQuotes }: QuotesWheelProps) => {
  return (
    <div className={styles.quotesWheel}>
      <MediaQuery minWidth={640}>
        <LeftChevron onClick={onLeftChevron} />
      </MediaQuery>
      <TransitionGroup className={styles.transitionGroup} data-direction={direction}>
        <CSSTransition
          in={true}
          appear={false}
          key={`quote-${activeQuote}`}
          timeout={TRANSITION_DURATION}
          classNames={{
            enter: styles['slide-enter'],
            enterDone: styles['slide-enter-done'],
            enterActive: styles['slide-enter-active'],
            exit: styles['slide-exit'],
            exitActive: styles['slide-exit-active'],
          }}
        >
          <div className={styles.quotesContainer}>
            <blockquote>
              <p>{displayQuotes[activeQuote].quotation}</p>
            </blockquote>
            <figcaption>
              &#xff0d; <cite>{displayQuotes[activeQuote].attribution}</cite>
            </figcaption>
          </div>
        </CSSTransition>
      </TransitionGroup>
      <MediaQuery minWidth={640}>
        <RightChevron onClick={onRightChevron} />
      </MediaQuery>
    </div>
  );

  function LeftChevron({ onClick }: Partial<QuoteArrowProps>) {
    return <QuoteArrow onClick={onClick} />;
  }

  function RightChevron({ onClick }: Partial<QuoteArrowProps>) {
    return <QuoteArrow right onClick={onClick} />;
  }

  function QuoteArrow({ right, onClick }: QuoteArrowProps) {
    const transform = right
      ? 'translate(8.000000, 13.500000) scale(-1, 1) translate(-8.000000, -13.500000) translate(0.000000, -1.000000)'
      : 'translate(0, -1.0)';
    const className = right ? cx(styles.chevron, styles.right) : cx(styles.chevron, styles.left);
    return (
      <div className={styles.chevronWrapper}>
        <svg
          onClick={onClick}
          className={className}
          width='1.6rem'
          height='2.8rem'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <desc>Created with Sketch.</desc>
          <defs></defs>
          <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.495'>
            <g id='quote-arrow-left' transform={`${transform}`} fill='#828282' fillRule='nonzero'>
              <path
                d='M4.778,14.857 L15.267,4.376 C15.7671718,3.87475638 15.9619394,3.14474968 15.7779356,2.46096636 C15.5939318,1.77718304 15.0591112,1.24350605 14.3749356,1.06096636 C13.6907599,0.878426662 12.9611718,1.07475638 12.461,1.576 L0.577,13.454 C-0.169868225,14.2026021 -0.195424162,15.4063748 0.519,16.186 L12.453,28.144 C12.9531718,28.6452436 13.6827599,28.8415734 14.3669356,28.6590337 C15.0511112,28.476494 15.5859318,27.942817 15.7699356,27.2590337 C15.9539394,26.5752503 15.7591718,25.8452436 15.259,25.344 L4.778,14.857 Z'
                id='Icon_ionic-ios-arrow-back'
              ></path>
            </g>
          </g>
        </svg>
      </div>
    );
  }
};

const LogosWheel = ({
  activeLogo,
  direction,
  steps,
  onSwipeLeft,
  onSwipeRight,
  onSetActiveLogo,
  displayLogos,
  showActiveLogoOnly,
}: LogosWheelProps) => {
  const isTransitioning = useRef(false);
  const dotStyle = {
    height: '0.6rem',
    width: '0.6rem',
    margin: '0 0.6rem',
    marginTop: '20rem',
    backgroundColor: '#828282',
    borderRadius: '50%',
    display: 'inline-block',
  };

  const Dot = ({ active }: DotProps) => {
    if (active) {
      return <span style={{ ...dotStyle, backgroundColor: '#ff6319' }} />;
    }

    return <span style={dotStyle} />;
  };

  const NUMBER_OF_LOGOS_RENDERED = showActiveLogoOnly && displayLogos.length < 7 ? displayLogos.length : 7;

  const handleSwipeLeft = () => {
    if (!isTransitioning.current) {
      onSwipeLeft();
    }
  };

  const handleSwipeRight = () => {
    if (!isTransitioning.current) {
      onSwipeRight();
    }
  };

  const getNextIndex = (currentIndex: number) => mod(currentIndex + 1, displayLogos.length);

  let reordered: ReorderedProps[] = [];
  const nBefore = displayLogos.length < 4 ? 0 : Math.floor(displayLogos.length / 2);
  const start = mod(activeLogo - nBefore, displayLogos.length);
  let i = 0;
  let index = start;

  while (i < displayLogos.length) {
    reordered.push(displayLogos[index]);
    index = getNextIndex(index);
    i += 1;
  }

  const tolerance = Math.floor(NUMBER_OF_LOGOS_RENDERED / 2);
  reordered = reordered.slice(nBefore - tolerance, nBefore + tolerance + 1); // +1 for the active logo itself

  return (
    <ReactGesture onSwipeLeft={handleSwipeLeft} onSwipeRight={handleSwipeRight}>
      <div className={styles.logosWheel}>
        <TransitionGroup className={styles.transitionGroup} data-direction={direction} data-steps={steps}>
          <CSSTransition
            in={true}
            appear={false}
            key={`logo-${activeLogo}`}
            timeout={TRANSITION_DURATION}
            classNames={{
              enter: styles['logo-enter'],
              enterDone: styles['logo-enter-done'],
              enterActive: styles['logo-enter-active'],
              exit: styles['logo-exit'],
              exitActive: styles['logo-exit-active'],
            }}
            onExit={() => (isTransitioning.current = true)}
            onExited={() => (isTransitioning.current = false)}
          >
            <figure className={showActiveLogoOnly ? styles.logosBar : cx(styles.logosBar, styles.opacityStyles)}>
              {reordered.map((l, index) => (
                <div
                  className={
                    showActiveLogoOnly
                      ? cx(styles.normalLogo, {
                          [`${styles.activeLogo}`]: tolerance === index,
                          [`${styles.hideLogo}`]: tolerance !== index,
                        })
                      : cx(styles.normalLogo, {
                          [`${styles.activeLogo}`]: tolerance === index,
                        })
                  }
                  key={`logo-${index}`}
                  onClick={() => handleClickLogo(index)}
                >
                  <img src={reordered[index].url} alt={reordered[index].name} />
                </div>
              ))}
            </figure>
          </CSSTransition>
        </TransitionGroup>
        <figure className={styles.indicators}>
          {displayLogos.map((l, index) =>
            activeLogo === index ? <Dot key={`dot-${index}`} active /> : <Dot key={`dot-${index}`} />,
          )}
        </figure>
      </div>
    </ReactGesture>
  );

  function handleClickLogo(index: number) {
    return onSetActiveLogo(index - tolerance);
  }
};

const TestimonialsSegment = ({ whiteBackground, isUae, showActiveLogoOnly }: TestimonialsSegmentProps): JSX.Element => {
  const [quotesIndex, setQuotesIndex] = useState(0);
  const [logosIndex, setLogosIndex] = useState(0);
  const [isVisitorFromCanada, setIsVisitorFromCanada] = useState(false);
  const [displayLogos, setDisplayLogos] = useState([...logos]);
  const [displayQuotes, setDisplayQuotes] = useState([...quotes]);
  const direction = useRef('left');
  const steps = useRef('single');

  useEffect(() => {
    async function checkCountry() {
      const country = await getCountryOriginBasedOnIp();

      if (country === 'Canada') {
        setIsVisitorFromCanada(true);
      }
    }

    checkCountry();
  }, []);

  useEffect(() => {
    if (isUae) {
      setDisplayLogos([...UAELogos]);
      setDisplayQuotes([...UAEQuotes]);
    } else if (isVisitorFromCanada) {
      setDisplayLogos([...canadianLogos]);
      setDisplayQuotes([...canadianQuotes, ...quotes]);
    } else {
      setDisplayLogos([...logos]);
      setDisplayQuotes([...quotes]);
    }
  }, []);

  const setActiveLogo = (displacement: number) => {
    const newLogoIndex = mod(logosIndex + displacement, displayLogos.length);
    const newQuotesIndex = mod(quotesIndex + displacement, displayQuotes.length);

    if (displacement > 0) {
      direction.current = 'left';
    } else {
      direction.current = 'right';
    }

    switch (Math.abs(displacement)) {
      case 2:
        steps.current = 'double';
        break;

      case 3:
        steps.current = 'triple';
        break;

      default:
      case 1:
        steps.current = 'single';
        break;
    }

    setLogosIndex(newLogoIndex);
    setQuotesIndex(newQuotesIndex);
  };

  const incrementIndices = function () {
    setActiveLogo(1);
  };

  const decrementIndices = function () {
    setActiveLogo(-1);
  };

  return (
    <section
      className={cx(styles.content, {
        [`${styles.whiteBackground}`]: whiteBackground,
      })}
      id='testimonials'
    >
      <SectionHeader center title='Learn Why Educators Fall in Love with Peekapak' />
      <QuotesWheel
        activeQuote={quotesIndex}
        direction={direction.current}
        onLeftChevron={decrementIndices}
        onRightChevron={incrementIndices}
        displayQuotes={displayQuotes}
      />
      <LogosWheel
        displayLogos={displayLogos}
        activeLogo={logosIndex}
        direction={direction.current}
        steps={steps.current}
        onSwipeLeft={incrementIndices}
        onSwipeRight={decrementIndices}
        onSetActiveLogo={setActiveLogo}
        showActiveLogoOnly={showActiveLogoOnly}
      />
    </section>
  );
};

export default TestimonialsSegment;
