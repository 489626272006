import styles from './LogosSegment.module.scss';

interface Props {
  title: string;
  subtitle: string;
  logos: string[];
  alternateStyles?: Record<string, string>;
}

const LogosSegment = ({ title, subtitle, logos, alternateStyles }: Props): JSX.Element => {
  const usingStyles = alternateStyles || styles;
  return (
    <section className={usingStyles.content}>
      <h1 className={usingStyles.title}>{title}</h1>
      <p>{subtitle}</p>
      <div className={usingStyles.imageContainer}>
        {logos.map((x) => (
          <img src={x} alt={x.toString()} key={x} />
        ))}
      </div>
    </section>
  );
};

export default LogosSegment;
