import { ReactNode } from 'react';
import PrimaryButton from '../PrimaryButton';
import cx from 'classnames';
import styles from './ExternalProviderButton.module.scss';

interface Props {
  className?: string;
  children?: ReactNode;
  disable?: boolean;
  onClick: () => unknown;
}

const ExternalProviderButton = (props: Props): JSX.Element => {
  const onClickIntercept = () => {
    if (!props.disable) {
      props.onClick();
    }
  };

  return (
    <PrimaryButton
      tabIndex={0}
      capitalize
      className={cx(props.className, styles.externalProviderButton, {
        [`${styles.disabled}`]: props.disable,
      })}
      onClick={onClickIntercept}
    >
      {props.children}
    </PrimaryButton>
  );
};

export default ExternalProviderButton;
