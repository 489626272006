import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';
import styles from './FormikStyles.module.scss';

interface Props {
  options: string[];
  label: string;
  name: string;
}

function RadioButtons(props: Props): JSX.Element {
  const { label, name, options, ...rest } = props;
  return (
    <div className={styles.radioGroup}>
      <label>{label}</label>
      <Field name={name} {...rest}>
        {({ field }: any) => {
          return options.map((option: any) => {
            return (
              <React.Fragment key={option.key}>
                <input
                  type='radio'
                  id={option.value}
                  {...field}
                  value={option.value}
                  checked={field.value === option.value}
                />
                <label htmlFor={option.value}>{option.key}</label>
              </React.Fragment>
            );
          });
        }}
      </Field>
      <div className={styles.errorHintContainer}>
        <ErrorMessage name={name} component={TextError} />
      </div>
    </div>
  );
}

export default RadioButtons;
