import SectionHeader from '../components/SectionHeader';
import logoClasslink from '../images/logos/logo-classlink.png';
import logoClever from '../images/logos/logo-clever-text.png';
import logoGoogleClassroom from '../images/logos/logo-google-classroom.png';
import styles from './IntegrationPartners.module.scss';

const logos = [logoClasslink, logoClever, logoGoogleClassroom];

const IntegrationPartners = (): JSX.Element => {
  return (
    <section className={styles.integrationPartnersSection}>
      <SectionHeader
        className={styles.sectionHeader}
        title='Integration Partners for Success'
        copy='Peekapak integrates with the following providers for single sign on
      and seamless rostering capabilities.'
      />
      <div className={styles.logosContainer}>
        {logos.map((logo) => (
          <img src={logo} alt={logo.toString()} key={logo} />
        ))}
      </div>
    </section>
  );
};

export default IntegrationPartners;
