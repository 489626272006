import { connect, ConnectedProps } from 'react-redux';
import { getLetterGradeFromNumberGrade } from './GlobalFunctions';
import { selectClassroom } from './Classrooms';
import { History } from 'history';
import { RootState } from './ApplicationState';

const mapStateToProps = (state: RootState) => ({
  classroom: selectClassroom(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  params: {
    unitId: string;
  };
  history: History;
  isPreviewMode: boolean;
  isAuthenticated: boolean;
};

const ContextCollectorAndRedirector = (props: Props) => {
  const { isPreviewMode, isAuthenticated } = props;
  if (!isPreviewMode && !isAuthenticated) {
    return null;
  }

  const unitId = props.params.unitId;
  const classroomName = props.classroom.className;
  const grade = getLetterGradeFromNumberGrade(props.classroom.grade);
  const newUnitId = `${unitId.substr(0, unitId.lastIndexOf('-'))}-${grade}`;

  setTimeout(() => {
    props.history.replace(`/${classroomName}/lessonPlan/${newUnitId}`);
  }, 1000);

  return null;
};

export default connector(ContextCollectorAndRedirector);
