import { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../SCSS/SignUpEpilogue.module.scss';

interface Props {
  redirect?: unknown;
  noPromoCode: unknown;
}
class SignUpEpilogue extends Component<Props> {
  render(): JSX.Element {
    const { redirect, noPromoCode } = this.props;

    return (
      <div className={styles.epilogueText}>
        <div className='spacer-10' />
        <p className='absCentre'>
          Already have an account?&nbsp;
          {redirect ? (
            <span>
              <Link
                to={{
                  pathname: '/login',
                  state: { nextPathname: redirect },
                }}
              >
                Log In
              </Link>
            </span>
          ) : (
            <span>
              <Link to='/login'>Log In</Link>
            </span>
          )}
        </p>
        {!noPromoCode && (
          <div>
            <div className='spacer-10' />
            <p className='absCentre'>
              Have a promo code?&nbsp;
              <span>
                <Link to='/redeem'>Redeem</Link>
              </span>
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default SignUpEpilogue;
