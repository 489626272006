import { loadStripe, Stripe } from '@stripe/stripe-js';
import { getConfiguration } from './GlobalFunctions';
let stripePromiseReady = false;
export let stripePromise: Stripe | null = null;
export async function initializeStripe(): Promise<void> {
  if (stripePromiseReady) return;
  const stripeApiKey = getConfiguration('stripe').apiKey;

  stripePromise = await loadStripe(stripeApiKey);
  // console.debug(
  //   '%cStripe initialization done',
  //   'background: black; color: red'
  // );
  stripePromiseReady = true;
  return;
}
