import { History } from 'history';
import Banner from '../Banner';

interface Props {
  history?: History;
}

const HeroBanner = ({ history, ...rest }: Props): JSX.Element => (
  <Banner {...rest} onClick={() => history?.push('/successStories')}>
    <span>
      <span>“Peekapak helps take the stress out of teaching SEL.”&nbsp;</span>
      <span
        style={{
          textTransform: 'uppercase',
          fontFamily: 'GT-Walsheim-Pro-Bold',
        }}
      >
        Learn more about Peekapak&apos;s Impact
      </span>
    </span>
  </Banner>
);

export default HeroBanner;
