import { Button } from 'react-bootstrap';
import combineClasses from 'classnames';
import styles from './TextButton.module.scss';

interface Props {
  inline?: boolean;
  uppercase?: boolean;
  bold?: boolean;
  grey?: boolean;
  red?: boolean;
  black?: boolean;
  blue?: boolean;
  darkGrey?: boolean;
  underlineHover?: boolean;
  visibleBorders?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  [k: string]: string | unknown | undefined;
}

const TextButton = ({
  inline,
  uppercase,
  bold,
  grey,
  red,
  black,
  blue,
  darkGrey,
  underlineHover,
  visibleBorders,
  disabled,
  onClick,
  ...otherProps
}: Props): JSX.Element => {
  const clickHandler = (() => {
    if (onClick && !disabled) {
      return onClick;
    }

    return () => {
      return;
    };
  })();

  const classes = combineClasses({
    [`${styles.textButton}`]: true,
    [`${styles.uppercase}`]: uppercase,
    [`${styles.bold}`]: bold,
    [`${styles.grey}`]: grey,
    [`${styles.red}`]: red,
    [`${styles.black}`]: black,
    [`${styles.blue}`]: blue,
    [`${styles.darkGrey}`]: darkGrey,
    [`${styles.inline}`]: inline,
    [`${styles.underlineHover}`]: underlineHover && !disabled,
    [`${styles.visibleBorders}`]: visibleBorders,
    [`${styles.disabled}`]: disabled,
  });

  const buttonProps = { ...otherProps };
  if (disabled) {
    buttonProps.disabled = true;
  }

  const ButtonComponent = () => (
    <Button {...buttonProps} onClick={clickHandler}>
      {otherProps.children}
    </Button>
  );
  if (inline) {
    return (
      <span className={classes}>
        <ButtonComponent />
      </span>
    );
  }
  return (
    <div className={classes}>
      <ButtonComponent />
    </div>
  );
};
export default TextButton;
