import { Children, cloneElement } from 'react';
import styles from './RadioButtonInput.module.scss';

export const RadioButtonInput = ({ children }: { children: JSX.Element[] }) => {
  return (
    <div id={styles.radioButtonInput}>
      {Children.map(children, (child) => {
        return cloneElement(child);
      })}
    </div>
  );
};

export default RadioButtonInput;
