import badge from './badge.png';
import AppStoreBadge from '../AppStoreBadge';

const GooglePlayStoreBadge = (): JSX.Element => {
  return (
    <AppStoreBadge
      id='playStoreBadge'
      targetUrl='https://play.google.com/store/apps/details?id=com.peekapak.mypeekaville.android'
      imageUrl={badge}
      altText='Download from Google Play Store'
    />
  );
};

export default GooglePlayStoreBadge;
