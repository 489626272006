import { MouseEventHandler } from 'react';
import styles from './Banner.module.scss';

interface Props {
  style?: Record<string, string>;
  onClick?: MouseEventHandler<HTMLDivElement>;
  children?: JSX.Element | JSX.Element[] | string;
}

const Banner = (props: Props): JSX.Element => {
  return (
    <div className={styles.banner} style={props.style} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export default Banner;
