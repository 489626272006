import * as React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import TitledCheckbox, { Title } from '../TitledCheckbox';
import Tooltip from '../Tooltip';

import libraryStyles from '../../SCSS/Library.module.scss';

interface Props {
  errortooltip: string;
}

const TOUCheckbox = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const isSmall = useMediaQuery({ maxWidth: 1280 });
  const moveTooltip = isSmall
    ? {
        transform: 'translate(-123px, 0px)',
        pointerEvents: undefined,
      }
    : {
        transform: 'translate(-35px, 0px)',
        pointerEvents: undefined,
      };
  return (
    <TitledCheckbox {...props} ref={ref} id='touCheckbox'>
      <Tooltip
        direction={isSmall ? 'bottom' : 'left'}
        arrowPosition={isSmall ? 'left-align' : undefined}
        showWithContent
        color='orange'
        content={props.errortooltip}
        translateTooltip={moveTooltip}
        maxWidth={isSmall ? '120%' : ''}
      >
        <Title>Terms of Use and Privacy Policy:</Title>
      </Tooltip>
      <span>
        Yes, I understand and accept Peekapak’s{' '}
        <Link to='/termsOfUse' className={libraryStyles.textLink}>
          Terms of Use
        </Link>{' '}
        and{' '}
        <Link to='/privacyPolicy' className={libraryStyles.textLink}>
          Privacy Policy
        </Link>
      </span>
    </TitledCheckbox>
  );
});

export default TOUCheckbox;
