import { Component } from 'react';
import Slider from 'rc-slider';

interface Props {
  tipFormatter: ((value: number) => React.ReactNode) | null;
  min: number;
  max: number;
  value: number;
  onAfterChange: (page: number) => void;
}
interface State {
  sliderValue: number;
  isUpdating: boolean;
}
export class FlipbookSlider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onSliderChange = this.onSliderChange.bind(this);
    this.onSliderAfterChange = this.onSliderAfterChange.bind(this);
    this.state = {
      sliderValue: this.props.min,
      isUpdating: false,
    };
  }

  onSliderChange(value: number) {
    if (value !== this.state.sliderValue) {
      this.setState({ isUpdating: true, sliderValue: value });
    }
  }

  onSliderAfterChange(value: number) {
    this.setState({ isUpdating: false });
    this.props.onAfterChange(value);
  }

  componentDidUpdate() {
    if (this.props.value !== this.state.sliderValue && !this.state.isUpdating) {
      this.setState({ sliderValue: this.props.value });
      return true;
    }

    return false;
  }

  render() {
    return (
      <Slider
        tipTransitionName='rc-slider-tooltip-zoom-down'
        min={this.props.min}
        max={this.props.max}
        value={this.state.sliderValue}
        onChange={this.onSliderChange}
        onAfterChange={this.onSliderAfterChange}
      />
    );
  }
}
