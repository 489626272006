import React from 'react';
import { useFormikContext } from 'formik';
import FormikControl from '../FormikContainer/FormikControl';

interface Props {
  label?: string;
  name: string;
  type: string;
  onChange?: (value: string) => string;
  [k: string]: string | unknown | undefined;
}

const UsernameInput = (props: Props): JSX.Element => {
  const { label, name, type, onChange, ...rest } = props;

  const formik = useFormikContext();

  const handleTrimTrailingSpace = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newValue = value.toLowerCase().trim();
    formik.setFieldValue(name, newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <FormikControl
      control='input'
      label={label}
      name={name}
      type={type}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        formik.handleChange(e);
        handleTrimTrailingSpace(e);
      }}
      {...rest}
    />
  );
};

export default UsernameInput;
