const Documents = {
  blogNewFeatures2021: 'http://blog.peekapak.com/new-features-for-2020-2021/',
  buyersGuide: 'https://peekaplatform.s3.amazonaws.com/media30/resources/marketing/PeekapakBuyersGuide.pdf',
  canadianStandards:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/Peekapak+Correlations+To+Canadian+Education+Expectations.pdf',
  caseStudy: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Case_Study.pdf',
  casel: 'https://peekaplatform.s3.amazonaws.com/media30/resources/marketing/Peekapak-CASEL+Alignment+-+2019+.pdf',
  characterCards: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Meet-the-Class+(english)_2024.pdf',
  characterCardsES: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Meet-the-Class+(spanish)_2024.pdf',
  characterCardsMENA: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Meet-the-Class_2024UAE.pdf',
  connectingToParents:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Step-by-step+guide-Connecting+to+parents.pdf',
  consentForm: 'https://peekaplatform.s3.amazonaws.com/media30/resources/help/Joining+myPeekaville+consent+form.pdf',
  consentFormSpanish:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/help/Joining+myPeekaville+consent+form+-+Spanish.pdf',
  federalFunding: 'https://peekaplatform.s3.amazonaws.com/media30/resources/funding/FederalFundingSheet_2023.pdf',
  guidedReadingLevels:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Peekapak_Guided_Reading_Levels_03-05-19.pdf',
  howToUseUnit:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Step-by-step+guide-How+to+use+a+Peekapak+unit.pdf',
  introLetter: 'https://s3.amazonaws.com/peekaplatform/media/resources/introLetter.pdf',
  meetThePeekapakPals: 'https://s3.amazonaws.com/peekaplatform/media/resources/meetThePeekapakPals.pdf',
  parentsEmailRequestForm: 'https://s3.amazonaws.com/peekaplatform/media/resources/parentsEmailRequestForm.pdf',
  posterCourage:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Courage-pk-UnitResources-Courage%20Poster.pdf',
  posterCourageSpanish: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Courage_Poster_Spanish.pdf',
  posterEmpathy:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Empathy-pk-UnitResources-Empathy%20Poster.pdf',
  posterEmpathySpanish: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Empathy_Poster_Spanish.pdf',
  posterGratitude:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Gratitude-pk-UnitResources-Gratitude%20Poster.pdf',
  posterGratitudeSpanish: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Gratitude_Poster_Spanish.pdf',
  posterHonesty:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Honesty-pk-UnitResources-Honesty%20Poster.pdf',
  posterHonestySpanish: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Honesty_Poster_Spanish.pdf',
  posterKindness:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Kindness-pk-UnitResources-Kindness%20Poster.pdf',
  posterKindnessSpanish: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Kindness_Poster_Spanish.pdf',
  posterOptimism:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Optimism-pk-UnitResources-Optimism%20Poster.pdf',
  posterOptimismSpanish: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Optimism_Poster_Spanish.pdf',
  posterPerseverance:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Perseverance-pk-UnitResources-Perseverance%20Poster.pdf',
  posterPerseveranceSpanish: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Perseverance_Poster_Spanish.pdf',
  posterRespect:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Respect-pk-UnitResources-Respect%20Poster.pdf',
  posterRespectSpanish: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Respect_Poster_Spanish.pdf',
  posterSelfRegulation:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Self-Regulation-pk-UnitResources-Self-Regulation%20Poster.pdf',
  posterSelfRegulationSpanish: 'https://s3.amazonaws.com/peekaplatform/media30/resources/SelfReg_Poster_Spanish.pdf',
  posterTeamwork:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Teamwork-pk-UnitResources-Teamwork%20Poster.pdf',
  posterTeamworkSpanish: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Teamwork_Poster_Spanish.pdf',
  posterIdentitySelfAwareness: 'https://peekaplatform.s3.amazonaws.com/media30/resources/Self+Awareness+Poster.pdf',
  posterStressRegulation: 'https://peekaplatform.s3.amazonaws.com/media30/resources/Stress+and+Regulation+Poster.pdf',
  posterDecisionMakingSkills:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/Decision+Making+Skills+Poster.pdf',
  posterBelongingPeerInfluence:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/Belonging+and+Peer+Influence+Poster.pdf',
  posterEmpathyConflictResolution:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/Empathy+and+Conflict+Resolution+Poster.pdf',
  postersSpanishMS: 'https://peekaplatform.s3.amazonaws.com/media30/resources/ES_MS_Posters.pdf',
  postersSpanishHS: 'https://peekaplatform.s3.amazonaws.com/media30/resources/ES_HS_Posters.pdf',
  posterClassNorms: 'https://peekaplatform.s3.amazonaws.com/media30/resources/Class-Norms-Poster.png',
  posterIdentitySelfAwarenessHS:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/Self-Awareness-HS-Poster.pdf',
  posterStressRegulationHS: 'https://peekaplatform.s3.amazonaws.com/media30/resources/Stress-Regulation-HS-Poster.pdf',
  posterDecisionMakingSkillsHS:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/Decision-Making-HS-Poster.pdf',
  posterBelongingPeerInfluenceHS: 'https://peekaplatform.s3.amazonaws.com/media30/resources/Belonging-HS-Poster.pdf',
  posterEmpathyConflictResolutionHS: 'https://peekaplatform.s3.amazonaws.com/media30/resources/Empathy-HS-Poster.pdf',
  pressKit: 'https://drive.google.com/drive/u/0/folders/1byysypNq2xv6oxDP2iTCHmCnOI4bwbrC',
  remoteLearningForFamilies:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/Remote+Learning+for+Families.pdf',
  research:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/PEEKAPAK_-_Research_%26_Design_Document_-_2019.pdf',
  schoolAdminToolKit: 'https://s3.amazonaws.com/peekaplatform/media30/resources/2024_Peekapak_School_Admin_Toolkit.pdf',
  readingLevelsMS: 'https://s3.amazonaws.com/peekaplatform/media30/resources/ReadingLevelsMS-Modified.pdf',
  readingLevelsHS: 'https://s3.amazonaws.com/peekaplatform/media30/resources/ReadingLevelsHS-Modified.pdf',
  settingUpYourClass:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Step-by-step+guide-Setting+up+your+class.pdf',
  successStoriesPKTo5: 'https://peekaplatform.s3.amazonaws.com/media30/Documents/Success-Stories-PK-to-5.pdf',
  successStoriesG6To8: 'https://peekaplatform.s3.amazonaws.com/media30/Documents/Success-Stories-6-to-8.pdf',
  twoYearCurriculumPlanner:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/2+Year+Curriculum+Planner+2018-2019.pdf',
  unitCheatSheet: 'https://peekaplatform.s3.amazonaws.com/media30/resources/help/myPeekaville+Units+Cheat+Sheet.pdf',
  requestInfoAndQuote: 'https://peekapak.com/requestInfo',
  blogCoronaVirusLessons: 'http://blog.peekapak.com/coronavirus-lesson-plans/',
  blogGetStartedPeekapak: 'http://blog.peekapak.com/getting-started-with-peekapak/',
  useAndImpactStudy:
    'https://peekaplatform.s3.amazonaws.com/media30/Documents/Peekapak+%26+Elizabeth+Public+Schools+2017-2018+-+Full+Report.pdf',
  videoHowToUsePeekapak: 'https://www.youtube.com/watch?v=gZMjCbXMJH8&feature=emb_logo',
  blogGetStartedMiddleSchool: 'https://blog.peekapak.com/getting-started-with-peekapak-middle-school/',
  getStartedPeekapakPKto5:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/Getting+Started+with+Peekapak+Elementary+(PK-5).pdf',
  getStartedPeekapakGrade6to8:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/Getting+Started+with+Peekapak+Middle+School+(6-8).pdf',
  getStartedPeekapakGrade9to12:
    'https://peekaplatform.s3.us-east-1.amazonaws.com/media30/resources/Getting+started+with+Peekapak+for+9-12.pdf',
  getStartedmyPeekaville:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/Getting+Started+with+myPeekaville.pdf',
  blogSite: 'http://blog.peekapak.com',
  careersSite: 'https://angel.co/company/peekapak/jobs',
  shopSite: 'https://shop.peekapak.com',
  bookDemo: 'https://calendly.com/amishah/30min',
  bookDemoTanya: 'https://calendly.com/tanya-peekapak/30min',
  minuteIntro: `https://m.youtube.com/watch?v=IP8gwmNaIQE`,
  middleSchoolResearch: `https://peekaplatform.s3.amazonaws.com/media30/resources/Peekapak's+Research-Informed+Middle+School+Curriculum+%5B2023%5D.pdf`,
  highSchoolResearch: `https://peekaplatform.s3.amazonaws.com/media30/Documents/Peekapak's+Research-Informed+HS+Curriculum.pdf`,
  freeRemoteResources: `https://blog.peekapak.com/free-access-to-peekapak-resources-for-canadian-families-2022/`,
  peekapalsActivityBook: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Peekapak_Pals_Coloring_Book.pdf',
  peekavilleStreetScape: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Peekaville_Streetscape.pdf',
  peekapalsCutouts: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Peekapak_Pals_Cutouts.pdf',
  peekapalsCutoutsBOTH: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Peekapak_Pals_CutoutsESandEN.pdf',
  youCanDoItColoring: 'https://s3.amazonaws.com/peekaplatform/media30/resources/You_Can_Do_It_Coloring_Page.pdf',
  valentinesCards: 'https://s3.amazonaws.com/peekaplatform/media30/resources/Valentines_Cards.pdf',
  peekapalsCertificates:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Peekapak_Certificates_Of_Achievement.pdf',
  peekapalsCertificatesMS:
    'https://peekaplatform.s3.amazonaws.com/media30/Documents/Certificates-of-Achievement-Secondary-School.pdf',
  selActivityCalendarNov2024PkTo5:
    'https://peekaplatform.s3.amazonaws.com/media30/SELActivityCalendars/SEL-activity-pk-5-Nov-2024.pdf',
  selActivityCalendarNov2024G6To12:
    'https://peekaplatform.s3.amazonaws.com/media30/SELActivityCalendars/SEL-activity-6-12-Nov-2024.pdf',
  peekapakElementaryTeacherConsoleMap:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/Peekapak+Elementary+Teacher+Console+Map.pdf',
  myPeekavilleStudentConsoleMap:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/myPeekaville+Students+Console+Map.pdf',
  peekapakMiddleSchoolTeacherConsoleMap:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/Peekapak+Middle+School+Teacher+Console+Map.pdf',
  peekapakMiddleSchoolStudentConsoleMap:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/Peekapak+Middle+School+Student+Console+Map.pdf',
  peekapakHumanInterestStoriesGuidedReadingLevels:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/Documents/Peekapak+Human+Interest+Stories+Guided+Reading+Levels.pdf',
  MTSSAlignmentAndPeekapak: 'https://peekaplatform.s3.amazonaws.com/media30/resources/MTSS-alignment-and-peekapak.pdf',
  NYSTLApprovedResourcesFromPeekapak:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/Documents/NYSTL+Approved+Resources+from+Peekapak.pdf',
  highSchoolWaitlist:
    'https://docs.google.com/forms/d/e/1FAIpQLScJbJxMhwy9gQw5yyqYrSbLkpU6jpAzbqmvB9Pb2XRHkZhFBw/viewform',
  highSchoolSnippet: 'https://peekaplatform.s3.amazonaws.com/media30/resources/high-school-snippet.pdf',
  abuDhabiCaseStudy: 'https://peekaplatform.s3.amazonaws.com/media30/Documents/Abu+Dhabi+Grammar+School+-+UAE.pdf',
  tulsaPublicSchoolsCaseStudy:
    'https://peekaplatform.s3.amazonaws.com/media30/Documents/Tulsa+Public+Schools+-+Case+Study.pdf',
  brightBeginningsCaseStudy:
    'https://peekaplatform.s3.amazonaws.com/media30/Documents/Bright+Beginnings+Case+Study.pdf',
  helenMKnightCaseStudy:
    'https://peekaplatform.s3.amazonaws.com/media30/Documents/Helen+M.+Knight%2C+Success+Story.pdf',
  studentPIICollection: 'https://peekaplatform.s3.amazonaws.com/media30/Documents/Student+PII+Sheet.pdf',
  pkto5JournalTemplates: 'https://peekaplatform.s3.amazonaws.com/media30/Documents/Peekapak-PK-5-Journal-Templates.pdf',
  pkto5JournalTemplatesES:
    'https://peekaplatform.s3.amazonaws.com/media30/Documents/Peekapak-PK-5-Journal-Templates-ES.pdf',
  emotionCards: 'https://peekaplatform.s3.amazonaws.com/media30/Documents/Emotion_Cards_UAE_print.pdf',
  pkto5MindfulnessColoringPrintout:
    'https://peekaplatform.s3.amazonaws.com/media30/Documents/PK-5-Mindfullness-Coloring-Printout.pdf',
  pkto5MindfulnessColoringPrintoutBOTH:
    'https://peekaplatform.s3.amazonaws.com/media30/Documents/PK-5-Mindfullness-Coloring-PrintoutBOTH.pdf',
  g6to12JournalTemplates:
    'https://peekaplatform.s3.amazonaws.com/media30/Documents/Peekapak-6-12-Journal-Templates.pdf',
  g6to12MindfullnessDoodling:
    'https://peekaplatform.s3.amazonaws.com/media30/Documents/G6-12-Mindfullness-Doodling.pdf',
  g6to12MindfullnessDoodlingBOTH:
    'https://peekaplatform.s3.amazonaws.com/media30/Documents/G6-12-Mindfullness-DoodlingBOTH.pdf',
  SMARTGoalTemplate: 'https://peekaplatform.s3.amazonaws.com/media30/Documents/Setting-SMART-Goals-with-Peekapak.pdf',
  SMARTGoalTemplateBOTH:
    'https://peekaplatform.s3.amazonaws.com/media30/Documents/Setting-SMART-Goals-with-PeekapakESandEN.pdf',
  pkTo5ScopeAndSequence: 'https://peekaplatform.s3.amazonaws.com/media30/Documents/EL-Scope-and-Sequence.pdf',
  MSScopeAndSequence: 'https://peekaplatform.s3.amazonaws.com/media30/Documents/MS-Scope-and-Sequence.pdf',
  HSScopeAndSequence: 'https://peekaplatform.s3.amazonaws.com/media30/Documents/HS-Scope-and-Sequence.pdf',
  ADGSCaseStudy: 'https://peekaplatform.s3.amazonaws.com/media30/Documents/ADGS+Case+Study.pdf',
  fundingToolkitForPeekapak:
    'https://peekaplatform.s3.amazonaws.com/media30/Documents/Funding+Toolkit+for+Peekapak.pdf',
  classroomEnergizersPkTo5: 'https://peekaplatform.s3.amazonaws.com/media30/Documents/Peekapak+Energizers+PK-5.pdf',
  classroomEnergizers6To12: 'https://peekaplatform.s3.amazonaws.com/media30/Documents/Peekapak+Energizers+Gr.+6-12.pdf',
  nationalIdentity:
    'https://docs.google.com/presentation/d/e/2PACX-1vRB8sNaaqbwE8Qkcyll-khEzCFyb61jtVa3UNnGsgJJBrFd91uIaSHM138v4FXo-KXnsyn4_1Pl0lJ5/embed?start=false&loop=false&delayms=3000',
  cop28K2: 'https://peekaplatform.s3.amazonaws.com/media30/Cop28/Learning+from+Nature%2C+PK-2.pdf',
  cop2835: 'https://peekaplatform.s3.amazonaws.com/media30/Cop28/Respecting+Nature+SEL%2C+Gr.3-5.pdf',
  cop28MS: 'https://peekaplatform.s3.amazonaws.com/media30/Cop28/Climate+Change+Anxiety+and+Action+Lesson%2C+G6-8.pdf',
  cop28HS: 'https://peekaplatform.s3.amazonaws.com/media30/Cop28/Climate+Change+Anxiety+and+Action+Lesson%2C+G9-12.pdf',
  peekapakUaeResearch:
    'https://peekaplatform.s3.us-east-1.amazonaws.com/publicResources/Peekapak+UAE+Research%2C+Guidelines%2C+%26+Development.pdf',
};

export default Documents;
