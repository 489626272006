import { useEffect, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { getCookie, setCookie } from '../../aaa';
import { Param, landingPageParams } from './tables';
import { getVisitorOriginBasedOnIp } from '../../GlobalFunctions';

interface Props {
  children: JSX.Element;
}

const LandingPageRoute = ({ children }: Props): JSX.Element => {
  const [visitorCountry, setVisitorCountry] = useState('');
  const [visitorRegion, setVisitorRegion] = useState('');

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const checkVisitorOrigin = async () => {
      const visitorCountryData = await getVisitorOriginBasedOnIp();
      setVisitorCountry(visitorCountryData.country);
      setVisitorRegion(visitorCountryData.region);
    };
    checkVisitorOrigin();
  }, []);

  useEffect(() => {
    let param;
    if (landingPageParams[`${visitorCountry}`]) {
      if (landingPageParams[`${visitorCountry}`][`${visitorRegion}`]) {
        param = (landingPageParams[`${visitorCountry}`][`${visitorRegion}`] as Param).param;
      } else {
        param = landingPageParams[`${visitorCountry}`].param;
      }
    } else {
      param = null;
    }

    if (!param) {
      return;
    } else if (param && location.search && location.search !== param) {
      return history.replace({
        pathname: '/',
        search: location.search,
      });
    } else {
      return history.replace({
        pathname: '/',
        search: param as string,
      });
    }
  }, [location.search, visitorCountry, visitorRegion]);

  return (
    <Route
      path='/'
      render={() => {
        return children;
      }}
    />
  );
};

export default LandingPageRoute;
