import { Switch } from 'react-router-dom';
import AuthenticatedRoute from '../AuthenticatedRoute';
import MiddleSchoolUnitPage from './MiddleSchoolUnitPage';
import MiddleSchoolLesson from '../MiddleSchoolLessonPage';

const MiddleSchoolLessonLayout = () => {
  return (
    <Switch>
      <AuthenticatedRoute path='/lessonPlan/:unitId/:language/:grade/lesson/:lesson/activity/:activity'>
        <MiddleSchoolLesson />
      </AuthenticatedRoute>
      <AuthenticatedRoute path='/lessonPlan/:unitId/:language/:grade'>
        <MiddleSchoolUnitPage />
      </AuthenticatedRoute>
    </Switch>
  );
};

export default MiddleSchoolLessonLayout;
