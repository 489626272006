import { Col, Row } from 'react-bootstrap';
import { Segment } from '../core/core';
import Notifications from '../components/Notifications';

import styles from './NotificationsPage.module.scss';
import utilityStyles from '../SCSS/common/utilityStyles.module.scss';
import segmentStyles from '../SCSS/Segment.module.scss';

interface Props {
  isAuthenticated: boolean;
}

const NotificationsPage = ({ isAuthenticated }: Props) => {
  return !isAuthenticated ? null : (
    <div className={`${styles.pageContainer} ${utilityStyles.clearfix}`}>
      <div className='spacer-30' />
      <Segment className={utilityStyles.line}>
        <Row>
          <Col xs={12} sm={6} className='vcenter'>
            <div className={segmentStyles.moduleTitle}>Notifications</div>
          </Col>
        </Row>
      </Segment>
      <div className={styles.notificationsContainer}>
        <Notifications />
      </div>
      <div className='spacer' />
    </div>
  );
};

export default NotificationsPage;
