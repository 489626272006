import VideoAndQuoteSection from '../../components/VideoAndQuoteSection';
import videoCoverImage from '../../images/UAELanding/uae-video-thumbnail.png';
import styles from './UAEVideoAndQuoteSection.module.scss';

const UAEVideoAndQuoteSection = (): JSX.Element => {
  return (
    <section className={styles.UAEVideoAndQuoteSection}>
      <VideoAndQuoteSection
        className={styles.videoAndQuoteSectionHeader}
        sectionPreTitle='peekapak in uae classrooms'
        sectionTitle='Empowering K12 Students in the UAE'
        quote="“After a few lessons, some of the teachers are already commenting on how kids are making connections in conversations and the things they've been talking about in the health curriculum.”"
        quoteFrom='－Ms. Kim Jackson, Vice Principal at Abu Dhabi Grammar School'
        videoCoverImageUrl={videoCoverImage}
        videoId='lErdUS3NntQPndgAoYKH02H2JaewCV4tVVYE7XhFRaXk'
        videoTitle='Peekapak in Abu Dhabi - Expanding our wellbeing resources into the UAE'
      />
    </section>
  );
};

export default UAEVideoAndQuoteSection;
