import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { useHistory } from 'react-router-dom';
import styles from './UnauthenticatedHeader.module.scss';

interface SignupProps {
  white?: boolean;
  orange?: boolean;
  inverted?: boolean;
  isMobileMenuActive: boolean;
  setIsMobileMenuActive: (arg0: boolean) => void;
}

interface RequestInfoProps {
  white?: boolean;
  isMobileMenuActive: boolean;
  setIsMobileMenuActive: (arg0: boolean) => void;
}

export const SignupButton = ({
  white,
  orange,
  inverted,
  isMobileMenuActive,
  setIsMobileMenuActive,
  ...rest
}: SignupProps): JSX.Element => {
  const history = useHistory();
  return (
    <PrimaryButton
      secondary
      small
      orange={orange}
      inverted={inverted}
      white={white}
      onClick={() => {
        history.push('/redirect/signup');
        if (isMobileMenuActive) setIsMobileMenuActive(false);
      }}
      {...rest}
    >
      Sign Up
    </PrimaryButton>
  );
};

export const RequestInfoButton = ({
  white,
  isMobileMenuActive,
  setIsMobileMenuActive,
  ...rest
}: RequestInfoProps): JSX.Element => {
  const history = useHistory();
  return (
    <PrimaryButton
      small
      white={white}
      onClick={() => {
        history.push('/requestInfo');
        if (isMobileMenuActive) setIsMobileMenuActive(false);
      }}
      {...rest}
    >
      Request Info
    </PrimaryButton>
  );
};
