import { createSlice } from '@reduxjs/toolkit';
import { MessageType } from '../peekapak-types/DataProtocolTypes';

export enum State {
  notLoaded = 'NOT_LOADED',
  loaded = 'LOADED',
}
interface MessagesState {
  state: State;
  messages: MessageType[];
  messageMark?: number;
}
export enum ActionType {
  LOAD = 'loadMessages',
  SET_MESSAGE_MARK = 'setMessageMark',
}

export const initialState: MessagesState = {
  state: State.notLoaded,
  messages: [],
  messageMark: undefined,
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    [ActionType.LOAD]: (state, action) => {
      state.state = State.loaded;
      state.messages = action.payload.messages;
      state.messageMark = action.payload.messageMark;
    },
    [ActionType.SET_MESSAGE_MARK]: (state, action) => {
      state.messageMark = action.payload;
    },
  },
});

export const { loadMessages, setMessageMark } = messagesSlice.actions;

export default messagesSlice.reducer;
