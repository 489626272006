import { useEffect, useRef, useState } from 'react';
import equals from 'fast-deep-equal';

export default function useAutoSave(data, handleSaveData, delay) {
  const prevData = useRef(data);
  const [saveState, setSaveState] = useState('saved');

  useEffect(() => {
    if (saveState === 'saved' && !equals(prevData.current, data)) {
      setSaveState('waitingToSave');
    }
    prevData.current = data;
  }, [saveState, data]);

  const hasDataChanged = saveState === 'waitingToSave';

  useEffect(() => {
    if (hasDataChanged) {
      if (!delay || delay === 0) {
        handleSaveData(data);
        setSaveState('saved');
        return;
      }
      const timeoutId = setTimeout(() => {
        setSaveState('saving');
        delayedSave(prevData.current).then((data) => {
          setSaveState('saved');
          handleSaveData(data);
        });
      }, delay);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [delay, hasDataChanged, data]);

  return saveState;
}

function delayedSave(data) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, 1000);
  });
}
