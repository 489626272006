import axios, { AxiosError } from 'axios';
import { QueryOutput } from '@aws-sdk/client-dynamodb';
import decodeJwt, { JwtPayload } from 'jwt-decode';
import { getStore } from './ApplicationState';
import { LoginState, refreshToken } from './UserManagement';
import { getRefreshedCognitoUser, isTokenUnavailable, waitUntilTokenIsAvailable } from './UserManagementHelpers';
import { fixedEncodeURIComponent, isTokenExpired } from './GlobalFunctions';
import { getCookie } from './aaa';
import {
  ComprehensionDataPoint,
  PeekavilleJournalEntry,
  StruggleDataPoint,
  StudentProgressData,
} from '../peekapak-types/PeekavilleGameDataTypes';
import {
  ClassLinkApiSection,
  ClassLinkApiStudentWithSection,
  ClassroomType,
  CleverApiSectionResult,
  CleverApiStudentResult,
  DistrictNames,
  FetchConfiguration,
  GetCleverTokenResponse,
  MergeOrSyncClassroomRequest,
  MyPeekavilleUnitLocksObjectType,
  SchoolModel,
  SellerNames,
  StudentDBObjectType,
  UserProfileType,
  CreateNewClassroomType,
  CheckTemporaryPasscodeResult,
  CoreStandardsData,
  StorySpec,
  ElementaryLessonPlan,
  ElementaryUnitCompletionStatus,
  ConsentRequest,
  InviteParentConnect,
  SendParentUpdate,
  GetCountryOriginResult,
} from '../peekapak-types/DataProtocolTypes';
import {
  Assignment,
  CreateAssignmentsForGroupRequest,
  CreateAssignmentsForIndividualRequest,
  ELAStandardsMap,
  ModuleResult,
  Unit,
} from '../peekapak-types/LessonPlanTypes';
import { Districts, MiddleSchoolCheckInEntry, ReportingMixpanelEvent } from '../peekapak-types/ReportDataExchangeTypes';
import { AxiosAugmentedError, EnterpriseUserError, ErrorType } from './AugmentedError';
import { UnitPosition } from './LessonPlan/ActivityStepPage';

let commonServicesRoot: string;
let aaaServicesRoot: string;
let peekavilleBackendRoot: string;
let cleverServicesRoot: string;
let binaryServicesRoot: string;
let stripeServicesRoot: string;
let zohoServicesRoot: string;
let lessonServicesRoot: string;
let mediaServicesRoot: string;
let classLinkServicesRoot: string;

if (import.meta.env.REACT_TEST_PROD_API) {
  commonServicesRoot = 'https://api.peekapak.com/commonservices';
  aaaServicesRoot = 'https://api.peekapak.com/aaaservices';
  peekavilleBackendRoot = 'https://api.peekapak.com/peekaville';
  binaryServicesRoot = 'https://api.peekapak.com/binaryservices';
  stripeServicesRoot = 'https://api.peekapak.com/stripeservices';
  zohoServicesRoot = 'https://api.peekapak.com/zohoservices';
  cleverServicesRoot = 'https://api.clever.com/v3.0';
  lessonServicesRoot = 'https://api.peekapak.com/lessonservices';
  mediaServicesRoot = 'https://api.peekapak.com/mediaservices';
  classLinkServicesRoot = 'https://api.peekapak.com/classlinkservices';
} else if (import.meta.env.MODE === 'development' || import.meta.env.REACT_USE_DEV_API) {
  commonServicesRoot = 'https://api.peekapak.com/commonservicesdev';
  aaaServicesRoot = 'https://api.peekapak.com/aaaservicesdev';
  peekavilleBackendRoot = 'https://api.peekapak.com/peekavilledev';
  binaryServicesRoot = 'https://api.peekapak.com/binaryservicesdev';
  stripeServicesRoot = 'https://api.peekapak.com/stripeservicesdev';
  zohoServicesRoot = 'https://api.peekapak.com/zohoservicesdev';
  cleverServicesRoot = 'https://api.clever.com/v3.0';
  lessonServicesRoot = 'https://api.peekapak.com/lessonservicesdev';
  mediaServicesRoot = 'https://api.peekapak.com/mediaservicesdev';
  classLinkServicesRoot = 'https://api.peekapak.com/classlinkservicesdev';
} else {
  commonServicesRoot = 'https://api.peekapak.com/commonservices';
  aaaServicesRoot = 'https://api.peekapak.com/aaaservices';
  peekavilleBackendRoot = 'https://api.peekapak.com/peekaville';
  binaryServicesRoot = 'https://api.peekapak.com/binaryservices';
  stripeServicesRoot = 'https://api.peekapak.com/stripeservices';
  zohoServicesRoot = 'https://api.peekapak.com/zohoservices';
  cleverServicesRoot = 'https://api.clever.com/v3.0';
  lessonServicesRoot = 'https://api.peekapak.com/lessonservices';
  mediaServicesRoot = 'https://api.peekapak.com/mediaservices';
  classLinkServicesRoot = 'https://api.peekapak.com/classlinkservices';
}

interface CleverError {
  code: number;
  error: string;
  data: Record<string, unknown>;
}

type AxiosWrappedEnterpriseUserError = AxiosError & {
  response: {
    data: {
      code: string;
      data: string;
      message: string;
      stack: string;
    };
  };
};
export function convertAxiosError(axiosError: AxiosWrappedEnterpriseUserError) {
  if (axiosError.response?.data?.code === 'EnterpriseUserError') {
    const eue = axiosError as AxiosWrappedEnterpriseUserError;
    return new EnterpriseUserError(eue.response.data.message, ErrorType.ENTERPRISE_USER, eue.response.data.data);
  } else if (axiosError.response) {
    interface ResponseData {
      message: string;
    }
    const responseData: ResponseData = axiosError.response.data as ResponseData;
    if (responseData.message === 'Unauthorized') {
      return new AxiosAugmentedError(
        responseData.message,
        ErrorType.UNAUTHORIZED,
        JSON.stringify(axiosError.response.headers),
      );
    }
    return new AxiosAugmentedError(
      responseData.message,
      ErrorType.UNKNOWN,
      JSON.stringify(axiosError.response.headers),
    );
  } else if (axiosError.request.response) {
    if (axiosError.request.status === 401) {
      return new AxiosAugmentedError(
        axiosError.request.response,
        ErrorType.UNAUTHORIZED,
        axiosError.request.responseURL,
      );
    }

    return new AxiosAugmentedError(
      axiosError.request.response,
      axiosError.request.status.toString(),
      axiosError.request.responseURL,
    );
  }
  return new AxiosAugmentedError(axiosError.message, ErrorType.UNKNOWN, 'N/A');
}

/* eslint-enable no-unused-vars */
export function retrievePeekavilleUnitLocks(
  classroomId: string,
  successCallback: (arg0: Response) => unknown,
  failureCallback: (arg0: Response) => unknown,
) {
  const userId = getUserId();
  const endpoint = `${peekavilleBackendRoot}/users/${userId}/${classroomId}/game-unit-locks`;
  getAuthenticatedFetchConfiguration('GET').then((configuration) => {
    fetchWithCallbacks(endpoint, configuration, successCallback, failureCallback);
  });
}

export function storePeekavilleUnitLocks(
  classroomId: string,
  peekavilleUnitLocks: MyPeekavilleUnitLocksObjectType,
  successCallback: (arg0: Response) => unknown,
  failureCallback: (arg0: Response) => unknown,
) {
  const userId = getUserId();
  const endpoint = `${peekavilleBackendRoot}/users/${userId}/${classroomId}/game-unit-locks`;
  peekavilleUnitLocks['Self Regulation'] = peekavilleUnitLocks['Self-Regulation'];
  delete peekavilleUnitLocks['Self-Regulation'];
  getAuthenticatedFetchConfiguration('PUT', peekavilleUnitLocks).then((configuration) => {
    fetchWithCallbacks(endpoint, configuration, successCallback, failureCallback);
  });
}

export async function fetchFromGallery(
  successCallback: (arg0: QueryOutput) => unknown,
  failureCallback: (arg0: { message: string }) => unknown,
  classroomId: string,
  numberOfItems?: number,
  applicationFilter?: string,
  exclusiveStartKey?: Record<string, unknown>,
) {
  const endpoint = `${commonServicesRoot}/classrooms/gallery`;
  const params = {
    classroomId,
    exclusiveStartKey,
    numberOfItems,
    applicationFilter,
  };
  const configuration = await getAuthenticatedFetchConfiguration('POST', params);
  fetchWithCallbacks(endpoint, configuration, successCallback, failureCallback);
}

export async function getUserProfile(): Promise<UserProfileType> {
  const userId = getUserId();
  const username = getUsername();
  const email = getUsersEmail();
  const idP = getCookie('peekapak.idP');
  const endpoint = `${aaaServicesRoot}/users/${userId}`;
  const params = {
    userId,
    email,
    username,
    idP,
  };

  const auth = await getAuthorizationToken();
  const data = params;
  try {
    const axiosResults = await axios({
      method: 'post',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
      data,
    });
    return axiosResults.data;
  } catch (error) {
    throw convertAxiosError(error as AxiosError);
  }
}

export function resendNewPasswordConfirmation(
  username: string,
  successCallback: (arg0: Record<string, unknown>) => unknown,
  failureCallback: (arg0: Record<string, unknown>) => unknown,
) {
  const endpoint = `${aaaServicesRoot}/admin/resend-new-password-confirmation`;
  const configuration = getFetchConfiguration('POST', {
    username,
  });
  fetchWithCallbacks(endpoint, configuration, successCallback, failureCallback);
}

export async function syncClassroom(
  mergeOrSyncClassroomRequest: MergeOrSyncClassroomRequest,
  successCallback: (arg0: { jobId: string }) => unknown,
  failureCallback: (arg0: Record<string, unknown>) => unknown,
) {
  const endpoint = `${aaaServicesRoot}/classrooms/import-async`;
  const params = { ...mergeOrSyncClassroomRequest };
  const configuration = await getAuthenticatedFetchConfiguration('POST', params);
  fetchWithCallbacks(endpoint, configuration, successCallback, failureCallback);
}

export function createTeacherAccount(
  createTeacherRequest: {
    createOrigin: string;
  },
  successCallback: (value: StudentDBObjectType) => unknown,
  failureCallback: (error: Error) => unknown,
) {
  createTeacherRequest.createOrigin = document.location.href;
  const endpoint = `${aaaServicesRoot}/users/create-teacher`;
  const configuration = getFetchConfiguration('POST', createTeacherRequest);
  fetchWithCallbacks(endpoint, configuration, successCallback, failureCallback);
}

export async function createStudentAccount(studentObj: StudentDBObjectType) {
  const endpoint = `${aaaServicesRoot}/users/create-student`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, studentObj, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export async function removeStudentAccount(
  userId: string,
  teachersUserId: string,
  classroomId: string,
): Promise<ClassroomType> {
  try {
    const token = await getAuthorizationToken();
    const endpoint = `${commonServicesRoot}/users/remove-student`;
    const data = {
      userId,
      teachersUserId,
      classroomId,
    };
    const updatedClassroom = await axios({
      method: 'put',
      url: endpoint,
      data,
      headers: {
        Authorization: token,
      },
    });
    return updatedClassroom.data;
  } catch (error: any) {
    console.error(`Error communicating with server: ${convertAxiosError(error)}`);
    throw convertAxiosError(error);
  }
}

export function optinUserEmail() {
  const endpoint = `${commonServicesRoot}/subscribe/all/subscribed`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'put',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export function getCleverToken(
  authorizationCode: string,
  redirectUri: string,
  successCallback: (arg0: GetCleverTokenResponse) => unknown,
  failureCallback: (arg0: Record<string, unknown>) => unknown,
) {
  const endpoint = `${aaaServicesRoot}/users/get-clever-token`;
  const configuration = getFetchConfiguration('POST', {
    authorizationCode,
    redirectUri,
  });
  fetchWithCallbacks(endpoint, configuration, successCallback, failureCallback);
}

type CleverSection = {
  data: {
    grade: string;
    id: string;
    name: string;
    students: Array<Record<string, unknown>>;
  };
};

export function getCleverOwnProfile(authorizationCode: string) {
  return new Promise((resolve, reject) => {
    const endpoint = `${cleverServicesRoot}/me`;
    const authorizationString = `Bearer ${authorizationCode}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: authorizationString,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const e = error as AxiosError<CleverError>;
        const errorText = `Error code ${e.response?.data.code}: ${e.response?.data.error}`;
        console.error(`Error communicating with server: ${errorText}`);
        reject(errorText);
        console.error(`Error communicating with server: ${convertAxiosError(error)}`);
        reject(convertAxiosError(error));
      });
  });
}

export async function getClassLinkSections(teacherSourcedId: string): Promise<ClassLinkApiSection[]> {
  const endpoint = `${classLinkServicesRoot}/users/${teacherSourcedId}/sections`;
  const authorizationToken = await getAuthorizationToken();
  try {
    const result = await axios.get(endpoint, {
      headers: {
        Authorization: authorizationToken,
      },
    });

    return result.data;
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    throw e;
  }
}

export async function getClassLinkStudents(sectionSourcedId: string): Promise<ClassLinkApiStudentWithSection[]> {
  const endpoint = `${classLinkServicesRoot}/sections/${sectionSourcedId}/students`;
  const authorizationToken = await getAuthorizationToken();
  try {
    const result = await axios.get(endpoint, {
      headers: {
        Authorization: authorizationToken,
      },
    });

    return result.data;
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    throw e;
  }
}

export function getCleverSections(authorizationCode: string, teacherCleverId: string): Promise<CleverApiSectionResult> {
  return new Promise((resolve, reject) => {
    const endpoint = `${cleverServicesRoot}/users/${teacherCleverId}/sections`;
    const authorizationString = `Bearer ${authorizationCode}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: authorizationString,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const e = error as AxiosError<CleverError>;
        if (e.response?.data.code === 91) {
          return resolve({ data: [] });
        }
        const errorText = `Error code ${e.response?.data.code}: ${e.response?.data.error}`;
        console.error(`Error communicating with server: ${errorText}`);
        reject(errorText);
      });
  });
}

export function getCleverSection(authorizationCode: string, sectionId: string): Promise<CleverSection> {
  return new Promise((resolve, reject) => {
    const endpoint = `${cleverServicesRoot}/sections/${sectionId}`;
    const authorizationString = `Bearer ${authorizationCode}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: authorizationString,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const e = error as AxiosError<CleverError>;
        const errorText = `Error code ${e.response?.data.code}: ${e.response?.data.error}`;
        console.error(`Error communicating with server: ${errorText}`);
        reject(errorText);
      });
  });
}

type StudentsInSection = {
  data: Array<{
    data: {
      id: string;
      name: {
        first: string;
        last?: string;
      };
      grade: string;
    };
  }>;
};

export function getAllMyCleverStudents(
  authorizationCode: string,
  teacherUserId: string,
): Promise<CleverApiStudentResult> {
  return new Promise((resolve, reject) => {
    const endpoint = `${cleverServicesRoot}/users/${teacherUserId}/mystudents`;
    const authorizationString = `Bearer ${authorizationCode}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: authorizationString,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        const e = error as AxiosError<CleverError>;
        if (e.response?.data.code === 91) {
          return resolve({ data: [] });
        }
        const errorText = `Error code ${e.response?.data.code}: ${e.response?.data.error}`;
        console.error(`Error communicating with server: ${errorText}`);
        reject(errorText);
      });
  });
}

export function getCleverStudent(authorizationCode: string, studentUserId: string) {
  return new Promise((resolve, reject) => {
    const endpoint = `${cleverServicesRoot}/users/${studentUserId}`;
    const authorizationString = `Bearer ${authorizationCode}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: authorizationString,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        const e = error as AxiosError<CleverError>;
        const errorText = `Error code ${e.response?.data.code}: ${e.response?.data.error}`;
        console.error(`Error communicating with server: ${errorText}`);
        reject(errorText);
      });
  });
}

export function getCleverStudentsInSection(authorizationCode: string, sectionId: string): Promise<StudentsInSection> {
  return new Promise((resolve, reject) => {
    const endpoint = `${cleverServicesRoot}/sections/${sectionId}/students`;
    const authorizationString = `Bearer ${authorizationCode}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: authorizationString,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        const e = error as AxiosError<CleverError>;
        if (e.response?.data.code === 91) {
          return resolve({ data: [] });
        }
        const errorText = `Error code ${e.response?.data.code}: ${e.response?.data.error}`;
        console.error(`Error communicating with server: ${errorText}`);
        reject(errorText);
      });
  });
}

export function updateStudent(
  userId: string,
  newData: unknown,
  successCallback: (arg0: Response) => unknown,
  failureCallback: (arg0: Response) => unknown,
) {
  const dataObj = {
    userId,
    newData,
  };
  const endpoint = `${commonServicesRoot}/users/update-student`;
  const configuration = getFetchConfiguration('PUT', dataObj);
  fetch(endpoint, configuration)
    .then((response) => {
      if (response.status === 200) {
        successCallback(response);
      } else {
        failureCallback(response);
      }
    })
    .catch((error) => {
      console.error(`Error communicating with server: ${error}`);
      failureCallback(error);
    });
}

export function updateDefaultStudentPassword(
  userId: string,
  defaultStudentPassword: string,
  successCallback: (arg0: Response) => unknown,
  failureCallback: (arg0: Response) => unknown,
) {
  const dataObj = {
    userId,
    defaultStudentPassword,
  };
  const endpoint = `${commonServicesRoot}/users/update-default-student-password`;
  const configuration = getFetchConfiguration('PUT', dataObj);
  fetch(endpoint, configuration)
    .then((response) => {
      if (response.status === 200) {
        successCallback(response);
      } else {
        failureCallback(response);
      }
    })
    .catch((error) => {
      console.error(`Error communicating with server: ${error}`);
      failureCallback(error);
    });
}

//
//
// private functions
//
//
export function getUser() {
  return getStore()?.getState()?.user?.cognitoProfile;
}

export function fetchWithCallbacks(
  endpoint: string,
  configuration: FetchConfiguration,
  successCallback: (arg0: any) => any,
  failureCallback: (arg0: any) => any,
) {
  fetch(endpoint, configuration)
    .then((response) => {
      return response.text();
    })
    .then((data) => {
      successCallback(data ? JSON.parse(data) : {});
    })
    .catch((error) => {
      console.error(`Error communicating with server: ${error}`);
      failureCallback(error);
    });
}

export function getFetchConfiguration(method: string, content?: Record<string, unknown>): FetchConfiguration {
  if (content) {
    const contentString: string = JSON.stringify(content);
    return {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': contentString.length.toString(),
      },
      body: contentString,
    };
  }

  return {
    method,
    headers: {},
  };
}

export async function getAuthenticatedFetchConfiguration(method: string, content?: Record<string, unknown>) {
  const configuration = getFetchConfiguration(method, content);
  const authorizationToken = await getAuthorizationToken();

  if (configuration.headers) {
    configuration.headers['Authorization'] = authorizationToken;
  }

  return configuration;
}

export function getUserId(): string {
  const idToken = getUser()?.getSignInUserSession()?.getIdToken()?.getJwtToken();
  if (!idToken) throw new Error(`User is not logged in`);
  const decodedToken = decodeJwt<JwtPayload>(idToken);
  if (!decodedToken?.sub) throw new Error(`User is not logged in`);
  return decodedToken.sub;
}

export function getUsername() {
  const username = getUser()?.getSignInUserSession()?.getIdToken()?.payload['cognito:username'];

  if (!username) throw new Error(`User is not logged in`);

  return username;
}

export function getUsersEmail() {
  return getUser()?.getSignInUserSession()?.getIdToken()?.payload.email;
}

/* eslint-disable complexity */
export async function getAuthorizationToken(): Promise<string> {
  const store = getStore();

  if (isTokenUnavailable()) {
    // console.debug(
    //   `%cRoot login state in transition, waiting...`,
    //   'background: #222; color: #bada55'
    // );
    try {
      await waitUntilTokenIsAvailable();
    } catch (e) {
      console.info(`Caught exception attempting to get Authorization token = `, e);
    }
  }

  const currentState = store.getState().user.loginState;

  if (currentState !== LoginState.loggedIn && currentState !== LoginState.authenticated) {
    throw new Error(`User is not logged in or not authenticated: ${currentState}`);
  }

  const expiry = getUser()?.getSignInUserSession()?.getIdToken()?.payload?.exp;

  if (expiry === undefined) throw new Error(`Expiry is undefined or user is not logged in`);

  if (!isTokenExpired(expiry)) {
    return `Bearer ${getUser()?.getSignInUserSession()?.getIdToken()?.getJwtToken()}`;
  }

  try {
    const cognitoUser = await getRefreshedCognitoUser();

    store.dispatch(refreshToken(cognitoUser));
    return `Bearer ${getUser()?.getSignInUserSession()?.getIdToken()?.getJwtToken()}`;
  } catch (error) {
    console.error(`Error communicating with server: ${error}`);
    throw error;
  }
}

export function getSchools(district?: string, tableOverride?: string): Promise<Array<SchoolModel>> {
  const endpoint = (() => {
    let ep = district ? `${commonServicesRoot}/admin/schools/${district}` : `${commonServicesRoot}/admin/schools`;
    if (tableOverride) ep += `/${tableOverride}`;
    return ep;
  })();

  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .get(endpoint, {
          headers: {
            Authorization: auth,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

interface CompletedLessonsResult {
  // unit name: number of completed lessons
  [key: string]: number;
}

export function getCompletedLessonsCounts(
  teachersEmail: string,
  schoolYear: string,
  tableOverride: string,
): Promise<CompletedLessonsResult[]> {
  return new Promise((resolve, reject) => {
    const endpoint = (() => {
      let ep = `${commonServicesRoot}/admin/count-completed-lessons/${fixedEncodeURIComponent(
        teachersEmail,
      )}/${schoolYear}`;

      if (tableOverride) ep += `/${tableOverride}`;
      return ep;
    })();
    getAuthorizationToken().then((auth) => {
      axios
        .get(endpoint, {
          headers: {
            Authorization: auth,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)} `);
          reject(convertAxiosError(error));
        });
    });
  });
}

export async function getMiddleSchoolUnit(unitId: string, _language: string, grade: string): Promise<Unit> {
  const endpoint = `${lessonServicesRoot}/unit/${unitId}/en/${grade} `;
  const authorizationToken = await getAuthorizationToken();

  try {
    const result = await axios.get(endpoint, {
      headers: {
        Authorization: authorizationToken,
      },
    });

    return result.data;
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e} `);
    throw e;
  }
}

export function getELAStandardsMap(grade: string): Promise<ELAStandardsMap> {
  const endpoint = `${lessonServicesRoot}/lessons/standards/grade/${grade} `;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .get(endpoint, {
          headers: { Authorization: auth },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)} `);
          reject(convertAxiosError(error));
        });
    });
  });
}

export function getMiddleSchoolModule(
  moduleId: string,
  _language: string,
  grade: string,
): Promise<Record<string, unknown>> {
  const endpoint = `${lessonServicesRoot}/module/${moduleId}/en/${grade} `;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .get(endpoint, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)} `);
          reject(convertAxiosError(error));
        });
    });
  });
}

export function getMiddleSchoolModules(moduleIDs: string[], _language: string, grade: string): Promise<ModuleResult[]> {
  const endpoint = `${lessonServicesRoot}/module`;
  const postBody = moduleIDs.map((x: string) => {
    return { id: x, language: 'en', grade: grade };
  });

  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export const createGroupAssignments = async (
  classroomId: string,
  unit: string,
  lesson: string,
  thisModule: string,
): Promise<{ jobId: string }> => {
  const endpoint = `${lessonServicesRoot}/assignments/create-async`;
  const teachersUserId = getUserId();
  const postBody: CreateAssignmentsForGroupRequest = {
    type: 'group',
    classroomId: classroomId,
    unit: unit,
    lesson: lesson,
    module: thisModule,
    teachersUserId,
  };
  const authorizationToken = await getAuthorizationToken();
  try {
    const result = await axios.post(endpoint, postBody, {
      headers: {
        Authorization: authorizationToken,
      },
    });
    return result.data;
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    throw e;
  }
};

export function createIndividualAssignment(
  classroomId: string,
  unit: string,
  lesson: string,
  thisModule: string,
  userId: string,
): Promise<Record<string, unknown>> {
  const teachersUserId = getUserId();
  const endpoint = `${lessonServicesRoot}/assignments/create`;
  const postBody: CreateAssignmentsForIndividualRequest = {
    type: 'individual',
    classroomId: classroomId,
    unit: unit,
    lesson: lesson,
    module: thisModule,
    userId: userId,
    teachersUserId,
  };
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export const deleteGroupAssignments = async (
  classroomId: string,
  unit: string,
  lesson: string,
  thisModule: string,
): Promise<{ jobId: string }> => {
  const endpoint = `${lessonServicesRoot}/assignments/delete-async`;
  const postBody = {
    type: 'group',
    classroomId: classroomId,
    unit: unit,
    lesson: lesson,
    module: thisModule,
  };
  const authorizationToken = await getAuthorizationToken();
  try {
    const result = await axios.delete(endpoint, {
      data: postBody,
      headers: { Authorization: authorizationToken },
    });
    return result.data;
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    throw e;
  }
};

export function deleteIndividualAssignments(
  userId: string,
  classroomId: string,
  unit: string,
  lesson: string,
  thisModule: string,
): Promise<Record<string, unknown>> {
  const endpoint = `${lessonServicesRoot}/assignments/delete`;
  const postBody = {
    type: 'individual',
    userId: userId,
    classroomId: classroomId,
    unit: unit,
    lesson: lesson,
    module: thisModule,
  };
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .delete(endpoint, { data: postBody, headers: { Authorization: auth } })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export function listGroupAssignments(
  classroomId: string,
  unit: string,
  lesson: string,
  thisModule: string,
  language: string,
  grade: number,
): Promise<Assignment[]> {
  const endpoint = `${lessonServicesRoot}/assignments/list`;
  const postBody = {
    type: 'group',
    classroomId: classroomId,
    unit: unit,
    lesson: lesson,
    module: thisModule,
    language,
    grade: grade,
  };
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export const listMultipleGroupAssignmentsByModule = async (
  classroomId: string,
  modules: string[],
): Promise<{ jobId: string }> => {
  const endpoint = `${lessonServicesRoot}/assignments/retrieve-async/by-module`;
  const postBody: { classroomId: string; modules: string[] } = {
    classroomId,
    modules,
  };
  const authorizationToken = await getAuthorizationToken();
  try {
    const result = await axios.post(endpoint, postBody, {
      headers: {
        Authorization: authorizationToken,
      },
    });
    return result.data;
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    throw e;
  }
};

export function listStudentAssignments(
  userId: string,
  language: string,
  grade: number,
  tableOverride?: string,
): Promise<Assignment[]> {
  const endpoint = (() => {
    const e = `${lessonServicesRoot}/assignments/list`;
    if (!tableOverride) return e;
    return `${e}/${tableOverride}`;
  })();
  const postBody = {
    type: 'individual',
    userId,
    language,
    grade: grade,
  };
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export function retrieveAssignment(userId: string, createdAt: number): Promise<Assignment[]> {
  return new Promise((resolve, reject) => {
    const endpoint = `${lessonServicesRoot}/assignments/retrieve`;
    const postBody = {
      userId,
      createdAt: createdAt,
    };
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export function retractAssignment(userId: string, createdAt: number): Promise<Record<string, unknown>> {
  return new Promise((resolve, reject) => {
    const endpoint = `${lessonServicesRoot}/assignments/retract`;
    const postBody = {
      userId,
      createdAt: createdAt,
    };
    getAuthorizationToken().then((auth) => {
      axios
        .patch(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export function saveAssignment(
  userId: string,
  createdAt: number,
  content: Record<string, unknown>[] | Record<string, unknown>,
  metawork?: Record<string, unknown>,
): Promise<Record<string, unknown>> {
  return new Promise((resolve, reject) => {
    const endpoint = `${lessonServicesRoot}/assignments/save`;
    const postBody = {
      userId: userId,
      createdAt: createdAt,
      work: { content: content },
      metawork: metawork || '',
    };
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export function submitAssignment(
  userId: string,
  createdAt: number,
  content: Record<string, unknown>[] | Record<string, unknown>,
  metawork?: Record<string, unknown>,
): Promise<Record<string, unknown>> {
  const endpoint = `${lessonServicesRoot}/assignments/submit`;
  const postBody = {
    userId: userId,
    createdAt: createdAt,
    work: { content: content },
    metawork: metawork || '',
  };
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export function deemLessonComplete(classroomId: string, unitId: string, lessonId: string): Promise<void> {
  const endpoint = `${commonServicesRoot}/classroom/${classroomId}/deem-complete/unit/${unitId}/lesson/${lessonId}`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .patch(endpoint, null, { headers: { Authorization: auth } })
        .then(() => resolve(console.log('Lesson marked as complete in database')))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export function undoDeemComplete(classroomId: string, unitId: string, lessonId: string): Promise<void> {
  const endpoint = `${commonServicesRoot}/classroom/${classroomId}/undo-deem-complete/unit/${unitId}/lesson/${lessonId}`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .patch(endpoint, null, { headers: { Authorization: auth } })
        .then(() => resolve(console.log('Lesson no longer deeemd complete in database')))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export function recordMiddleSchoolJournalEntry(
  userId: string,
  feeling: string,
  journalEntry: string,
  isTeacherRequested: boolean,
): Promise<void> {
  const endpoint = `${peekavilleBackendRoot}/users/${userId}/journal`;
  const postBody = {
    feeling: feeling,
    journalEntry: journalEntry,
    isTeacherRequested: isTeacherRequested,
  };
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export function getVideoURL(assetId: string): Promise<string> {
  return new Promise((resolve, reject) => {
    getAuthorizationToken()
      .then((auth) => {
        const endpoint = `${mediaServicesRoot}/video-url/service/mux/asset/${assetId}`;
        axios
          .get(endpoint, { headers: { Authorization: auth } })
          .then((response) => resolve(response.data.url))
          .catch((error) => {
            console.error(`Error communicating with server: ${convertAxiosError(error)}`);
            reject(convertAxiosError(error));
          });
      })
      .catch((error) => {
        if ((error as Error)?.message.includes('User is not logged in')) {
          const endpoint = `${mediaServicesRoot}/video-url/public/service/mux/asset/${assetId}`;
          axios
            .get(endpoint)
            .then((response) => resolve(response.data.url))
            .catch((error) => {
              console.error(`Error communicating with server: ${convertAxiosError(error)}`);
              reject(convertAxiosError(error));
            });

          return;
        }

        reject(error);
      });
  });
}

export function getUsers(schoolId: string, tableOverride: string): Promise<string> {
  const endpoint = (() => {
    let ep = `${commonServicesRoot}/admin/users-async/by-school/${schoolId}`;
    if (tableOverride) ep += `/${tableOverride}`;
    return ep;
  })();
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .get(endpoint, {
          headers: {
            Authorization: auth,
          },
        })
        .then((response) => resolve(response.data.jobId))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export function storeNewSchool(schoolModel: SchoolModel): Promise<void> {
  const endpoint = `${commonServicesRoot}/admin/schools/new`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'post',
        url: endpoint,
        data: schoolModel,
        headers: {
          Authorization: auth,
        },
      })
        .then(() => resolve())
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

interface QueryMixPanelParams {
  jql: string;
  database: string;
  tableOverride?: string;
}

export function queryMixpanelByGroup(
  script: string,
  database: string,
  tableOverride?: string,
): Promise<ReportingMixpanelEvent[]> {
  const endpoint = `${binaryServicesRoot}/admin/query-mixpanel-by-group`;
  const payload: QueryMixPanelParams = (() => {
    const p: QueryMixPanelParams = {
      jql: script,
      database,
    };
    if (tableOverride) {
      p.tableOverride = tableOverride;
    }
    return p;
  })();
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'post',
        url: endpoint,
        data: JSON.stringify(payload),
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => {
          resolve(response.data);
        })

        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
            return reject(
              new Error(
                `Server experienced error trying to retrieve analytics: ${error.response.status}. Please try again or contact Peekapak Support`,
              ),
            );
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error(error.request);
            return reject(
              new Error(`Did not receive response from server. Please try again or contact Peekapak Support`),
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            return reject(
              new Error(
                `A problem occurred while retrieving analytics data. Please try again or contact Peekapak Support`,
              ),
            );
          }
        });
    });
  });
}

export function queryMixpanelByGroupV2(
  script: string,
  database: string,
  tableOverride?: string,
): Promise<ReportingMixpanelEvent[]> {
  const endpoint = `${binaryServicesRoot}/admin/query-mixpanel-by-group/v2`;
  const payload: QueryMixPanelParams = (() => {
    const p: QueryMixPanelParams = {
      jql: script,
      database,
    };
    if (tableOverride) {
      p.tableOverride = tableOverride;
    }
    return p;
  })();
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'post',
        url: endpoint,
        data: JSON.stringify(payload),
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
            return reject(
              new Error(
                `Server experienced error trying to retrieve analytics: ${error.response.status}. Please try again or contact Peekapak Support`,
              ),
            );
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error(error.request);
            return reject(
              new Error(`Did not receive response from server. Please try again or contact Peekapak Support`),
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            return reject(
              new Error(
                `A problem occurred while retrieving analytics data. Please try again or contact Peekapak Support`,
              ),
            );
          }
        });
    });
  });
}

export function getCountryOriginBasedOnIp(): Promise<GetCountryOriginResult> {
  return new Promise((resolve, reject) => {
    const endpoint = `${commonServicesRoot}/users/get-country-origin`;
    axios({
      method: 'get',
      url: endpoint,
    })
      .then((response) => resolve(response.data))
      .catch((error) => {
        console.error(`Error communicating with server: ${convertAxiosError(error)}`);
        reject(convertAxiosError(error));
      });
  });
}

export function validateLicenseCode(code: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    if (code.length < 12) {
      resolve(false);
    }

    const endpoint = `${aaaServicesRoot}/license-code/${code}/validate`;
    axios({
      method: 'get',
      url: endpoint,
    })
      .then((response) => resolve(response.data.result))
      .catch((error) => {
        console.error(`Error communicating with server: ${convertAxiosError(error)}`);
        reject(convertAxiosError(error));
      });
  });
}

export function redeemLicenseCode(code: string): Promise<Record<string, unknown>> {
  const userId = getUserId();
  const endpoint = `${aaaServicesRoot}/license-code/${code}/redeem/${userId}`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'put',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export async function getJob(id: string): Promise<{
  status: string;
  data: string;
}> {
  const endpoint = `${commonServicesRoot}/jobs/${id}`;
  const auth = await getAuthorizationToken();
  try {
    const response = await axios({
      method: 'get',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
    });

    const jobStatus: string = response.data?.status;

    if (!jobStatus || (jobStatus !== 'Done' && jobStatus !== 'Error')) {
      return response.data;
    }

    if (response.data?.isEmbedded) {
      return response.data;
    }

    const { s3 } = JSON.parse(response.data.data);
    const { region, bucket, key } = s3;

    const getS3JsonObjectEndpoint = `${commonServicesRoot}/get-large-data-block/${region}/${bucket}/${fixedEncodeURIComponent(
      key,
    )}`;

    const retrievedData = await axios({
      method: 'get',
      url: getS3JsonObjectEndpoint,
      headers: {
        Authorization: auth,
      },
    });

    return {
      status: response.data.status,
      data: retrievedData.data,
    };
  } catch (error) {
    const e = error as AxiosError;
    console.error(`Error communicating with server: ${convertAxiosError(e)}`);
    throw convertAxiosError(e);
  }
}

export function getSlideShowPointers(): Promise<{
  url: string;
  thumbnailUrl: string;
}> {
  const endpoint = `${commonServicesRoot}/configuration/SlideShowPointers`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'get',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export async function updateClassroom(
  teachersEmail: string,
  className: string,
  newData: unknown,
  returnUpdatedClassroomData = false,
): Promise<ClassroomType> {
  const endpoint = `${commonServicesRoot}/classroom`;
  const payload = {
    teachersEmail,
    className,
    newData,
    returnUpdatedClassroomData,
  };

  try {
    const token = await getAuthorizationToken();
    const result = await axios({
      method: 'put',
      url: endpoint,
      data: payload,
      headers: {
        Authorization: token,
      },
    });

    return result.data;
  } catch (error: any) {
    console.error(`Error communicating with server: ${convertAxiosError(error)}`);
    throw convertAxiosError(error);
  }
}

export function getFromApplicationConfiguration(configurationName: string): Promise<Record<string, unknown>> {
  const endpoint = `${commonServicesRoot}/configuration/${configurationName}`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'get',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export async function getResellers(): Promise<Record<string, string>[]> {
  const data = (await getFromApplicationConfiguration('SellerNames')) as unknown as SellerNames;
  const asList: { id: string; name: string }[] = Object.keys(data).map((e) => ({
    id: e,
    name: data[e].name,
  }));
  return asList;
}

export async function getDistricts(): Promise<Districts> {
  const data = (await getFromApplicationConfiguration('DistrictNames')) as unknown as DistrictNames;
  const asList: Districts = Object.keys(data).map((e) => ({
    id: e,
    name: data[e].name,
  }));
  return asList;
}

export async function getStudentNumberLimits(): Promise<Record<string, unknown>> {
  return await getFromApplicationConfiguration('StudentNumberLimits');
}

export async function createClassroom(classroomParams: CreateNewClassroomType): Promise<string> {
  const endpoint = `${commonServicesRoot}/classroom`;

  try {
    const token = await getAuthorizationToken();
    const result = await axios({
      method: 'post',
      url: endpoint,
      data: classroomParams,
      headers: {
        Authorization: token,
      },
    });

    return result.data.classroomId;
  } catch (error: any) {
    console.error(`Error communicating with server: ${convertAxiosError(error)}`);
    throw convertAxiosError(error);
  }
}
export async function removeClassroom(classroomId: string): Promise<void> {
  const endpoint = `${commonServicesRoot}/classroom/${fixedEncodeURIComponent(classroomId)}`;

  try {
    const token = await getAuthorizationToken();
    await axios({
      method: 'delete',
      url: endpoint,
      headers: {
        Authorization: token,
      },
    });
  } catch (error: any) {
    console.error(`Error communicating with server: ${convertAxiosError(error)}`);
    throw convertAxiosError(error);
  }
}

export async function getClassroom(classroomId: string, tableOverride?: string): Promise<ClassroomType> {
  const endpoint = (() => {
    const e = `${commonServicesRoot}/classroom/${fixedEncodeURIComponent(classroomId)}`;
    if (!tableOverride) return e;
    return `${e}/${tableOverride}`;
  })();

  try {
    const token = await getAuthorizationToken();
    const result = await axios({
      method: 'get',
      url: endpoint,
      headers: {
        Authorization: token,
      },
    });

    return result.data;
  } catch (error: any) {
    console.error(`Error communicating with server: ${convertAxiosError(error)}`);
    throw convertAxiosError(error);
  }
}

export function getClassrooms(email: string, tableOverride?: string): Promise<ClassroomType[]> {
  const endpoint = (() => {
    const e = `${commonServicesRoot}/classroom/${fixedEncodeURIComponent(email)}/by-email`;
    if (!tableOverride) return e;
    return `${e}/${tableOverride}`;
  })();

  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'get',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export function getGameProgress(userId: string, tableOverride: string): Promise<Array<ClassroomType>> {
  const endpoint = (() => {
    const e = `${peekavilleBackendRoot}/users/${userId}/game-progress`;
    if (!tableOverride) return e;
    return `${e}/${tableOverride}`;
  })();
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'get',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export async function resetIndividualPeekavilleProgress(userId: string): Promise<ClassLinkApiSection[]> {
  const endpoint = `${peekavilleBackendRoot}/users/${userId}/game-progress/reset-quests`;
  const authorizationToken = await getAuthorizationToken();
  try {
    const result = await axios({
      method: 'put',
      url: endpoint,
      headers: {
        Authorization: authorizationToken,
      },
    });
    return result.data;
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    throw e;
  }
}

export async function resetClassPeekavilleProgress(classroomId: string): Promise<ClassLinkApiSection[]> {
  const endpoint = `${peekavilleBackendRoot}/classroom/${classroomId}/game-progress/reset-quests-async`;
  const authorizationToken = await getAuthorizationToken();
  try {
    const result = await axios({
      method: 'put',
      url: endpoint,
      headers: {
        Authorization: authorizationToken,
      },
    });
    return result.data;
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    throw e;
  }
}

export function getJournals(userId: string, tableOverride?: string): Promise<Array<PeekavilleJournalEntry>> {
  const endpoint = (() => {
    const e = `${peekavilleBackendRoot}/users/${userId}/journals`;
    if (!tableOverride) return e;
    return `${e}/${tableOverride}`;
  })();

  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'get',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export async function getPeekavilleComprehensionData(userId: string): Promise<Array<ComprehensionDataPoint>> {
  const endpoint = `${peekavilleBackendRoot}/users/${userId}/journals/comprehension`;
  const authorizationToken = await getAuthorizationToken();

  try {
    const result = await axios.get(endpoint, {
      headers: {
        Authorization: authorizationToken,
      },
    });

    return result.data;
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    throw e;
  }
}

export function getMiddleSchoolJournals(userId: string): Promise<Array<MiddleSchoolCheckInEntry>> {
  const endpoint = `${peekavilleBackendRoot}/users/${userId}/journals`;

  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'get',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

export function getStruggleData(userId: string): Promise<Array<StruggleDataPoint>> {
  const endpoint = `${peekavilleBackendRoot}/users/${userId}/journals/struggle`;

  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'get',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(`Error communicating with server: ${convertAxiosError(error)}`);
          reject(convertAxiosError(error));
        });
    });
  });
}

interface LicenseAndKeyRing {
  licenseLevel: string;
  licenseExpires: number;
  flags: Record<string, boolean>;
  keyRing: string[];
}

export async function activateTrial(): Promise<LicenseAndKeyRing> {
  const userId = getUserId();
  const auth = await getAuthorizationToken();
  const endpoint = `${aaaServicesRoot}/users/${userId}/activate-trial`;

  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(`Error communicating with server: ${convertAxiosError(error)}`);
        reject(convertAxiosError(error));
      });
  });
}

export async function updateUserProfile(newUserData: Record<string, unknown>): Promise<Record<string, unknown>> {
  const userId = getUserId();
  const auth = await getAuthorizationToken();
  const endpoint = `${aaaServicesRoot}/users/${userId}/update`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: endpoint,
      data: newUserData,
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(`Error communicating with server: ${convertAxiosError(error)}`);
        reject(convertAxiosError(error));
      });
  });
}

export async function getCustomer(): Promise<Record<string, unknown>> {
  const userId = getUserId();
  const auth = await getAuthorizationToken();
  const endpoint = `${stripeServicesRoot}/get-customer`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: endpoint,
      data: {
        userId,
      },
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(`Error communicating with server: ${convertAxiosError(error)}`);
        reject(convertAxiosError(error));
      });
  });
}

export async function createSubscription(
  customerId: string,
  paymentMethodId: string,
  tier: string,
  region: string,
  promoCode?: string,
): Promise<Record<string, unknown>> {
  const userId = getUserId();
  const auth = await getAuthorizationToken();
  const endpoint = `${stripeServicesRoot}/create-subscription`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: endpoint,
      data: {
        userId,
        customerId,
        paymentMethodId,
        tier,
        region,
        promoCode,
      },
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(`Error communicating with server: ${convertAxiosError(error)}`);
        reject(convertAxiosError(error));
      });
  });
}

export async function previewInvoice(
  tier: string,
  region: string,
  promoCode: string,
): Promise<Record<string, unknown>> {
  const auth = await getAuthorizationToken();
  const endpoint = `${stripeServicesRoot}/preview-invoice`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: endpoint,
      data: {
        tier,
        region,
        promoCode,
      },
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(`Error communicating with server: ${convertAxiosError(error)}`);
        reject(convertAxiosError(error));
      });
  });
}

export async function getPaymentStatus(): Promise<Record<string, unknown>> {
  const userId = getUserId();
  const auth = await getAuthorizationToken();
  const endpoint = `${stripeServicesRoot}/get-payment-status/${userId}`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(`Error communicating with server: ${convertAxiosError(error)}`);
        reject(convertAxiosError(error));
      });
  });
}

export async function validatePromoCode(code: string): Promise<Record<string, unknown>> {
  const auth = await getAuthorizationToken();
  const endpoint = `${stripeServicesRoot}/promo-code/${code}/validate`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => resolve(response.data.result))
      .catch((error) => {
        console.error(`Error communicating with server: ${convertAxiosError(error)}`);
        reject(convertAxiosError(error));
      });
  });
}

export async function createLead(data: Record<string, unknown>): Promise<Record<string, unknown>> {
  const endpoint = `${zohoServicesRoot}/create-lead`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: endpoint,
      data,
    })
      .then((response) => resolve(response.data.result))
      .catch((error) => {
        console.error(`Error communicating with server: ${convertAxiosError(error)}`);
        reject(convertAxiosError(error));
      });
  });
}

//
// returns true if the passcode is valid
//
export async function checkTemporaryPasscode(passcode: string): Promise<CheckTemporaryPasscodeResult> {
  const endpoint = `${commonServicesRoot}/check-temporary-passcode/${passcode}/`;
  const axiosResult = await axios.get(endpoint);
  const result: CheckTemporaryPasscodeResult = axiosResult.data;
  return result;
}

export async function getCoreStandards(): Promise<CoreStandardsData> {
  const endpoint = `${commonServicesRoot}/core-standards/`;
  const auth = await getAuthorizationToken();
  const axiosResult = await axios({
    method: 'get',
    url: endpoint,
    headers: {
      Authorization: auth,
    },
  });
  const result: CoreStandardsData = axiosResult.data;
  return result;
}

export async function getStorySpec(storyId: string): Promise<StorySpec> {
  const endpoint = `${commonServicesRoot}/story/${fixedEncodeURIComponent(storyId)}`;
  try {
    const axiosResult = await axios({
      method: 'get',
      url: endpoint,
    });
    const result: StorySpec = axiosResult.data;
    return result;
  } catch (error) {
    throw convertAxiosError(error as AxiosError);
  }
}

export async function setMessageMark(userId: string, newMessageMark: number): Promise<void> {
  const endpoint = `${commonServicesRoot}/message-mark/${fixedEncodeURIComponent(userId)}/${newMessageMark}`;
  const auth = await getAuthorizationToken();
  try {
    await axios({
      method: 'put',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
    });
    return;
  } catch (error) {
    throw convertAxiosError(error as AxiosError);
  }
}

export async function getElementaryUnit(unitName: string): Promise<ElementaryLessonPlan> {
  const endpoint = `${lessonServicesRoot}/elementary-unit/${fixedEncodeURIComponent(unitName)}/published`;
  const auth = await getAuthorizationToken();
  try {
    const axiosResult = await axios({
      method: 'get',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
    });
    const result: ElementaryLessonPlan = axiosResult.data;
    return result;
  } catch (error) {
    throw convertAxiosError(error as AxiosError);
  }
}

export async function getCompletionStatus(
  classroomId: string,
  unitId: string,
): Promise<ElementaryUnitCompletionStatus> {
  const endpoint = `${commonServicesRoot}/completion-status/${classroomId}/${unitId}`;
  const auth = await getAuthorizationToken();
  try {
    const axiosResult = await axios({
      method: 'get',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
    });
    const result: ElementaryUnitCompletionStatus = axiosResult.data;
    return result;
  } catch (error) {
    throw convertAxiosError(error as AxiosError);
  }
}

export async function getRecentMessages(targetEmail: string, afterTime: number) {
  const endpoint = `${commonServicesRoot}/messages/${targetEmail}/${afterTime}`;
  const auth = await getAuthorizationToken();
  try {
    const axiosResult = await axios({
      method: 'get',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
    });
    const result = axiosResult.data;
    return result;
  } catch (error) {
    throw convertAxiosError(error as AxiosError);
  }
}

async function updateElementaryCompletion(
  classroomId: string,
  unitId: string,
  unitPosition: UnitPosition,
  operation: 'reset' | 'complete',
) {
  const endpoint = `${lessonServicesRoot}/elementary-completion/${operation}`;
  const auth = await getAuthorizationToken();
  const data = {
    classroomId,
    unitId,
    unitPosition,
  };
  try {
    await axios({
      method: 'put',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
      data,
    });
    return;
  } catch (error) {
    throw convertAxiosError(error as AxiosError);
  }
}
export async function markStepComplete(classroomId: string, unitId: string, unitPosition: UnitPosition) {
  await updateElementaryCompletion(classroomId, unitId, unitPosition, 'complete');
}

export async function resetStep(classroomId: string, unitId: string, unitPosition: UnitPosition) {
  await updateElementaryCompletion(classroomId, unitId, unitPosition, 'reset');
}

export async function generateConsentRequests(requestList: ConsentRequest[]) {
  const endpoint = `${peekavilleBackendRoot}/admin/consent-request-items`;
  const auth = await getAuthorizationToken();
  const data = requestList;
  try {
    const axiosResults = await axios({
      method: 'post',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
      data,
    });
    return axiosResults.data;
  } catch (error) {
    throw convertAxiosError(error as AxiosError);
  }
}

export async function inviteParent(request: InviteParentConnect) {
  const endpoint = `${aaaServicesRoot}/invite-parent`;
  const auth = await getAuthorizationToken();
  const data = request;
  try {
    const axiosResults = await axios({
      method: 'post',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
      data,
    });
    return axiosResults.data;
  } catch (error) {
    throw convertAxiosError(error as AxiosError);
  }
}

export async function sendParentEmailUpdateAndActivity(request: SendParentUpdate) {
  const endpoint = `${commonServicesRoot}/send-parent-update`;
  const auth = await getAuthorizationToken();
  const data = request;
  try {
    const axiosResults = await axios({
      method: 'post',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
      data,
    });
    return axiosResults.data;
  } catch (error) {
    throw convertAxiosError(error as AxiosError);
  }
}

export async function getPlatformMode() {
  const endpoint = `${aaaServicesRoot}/platform-mode`;
  try {
    const axiosResults = await axios({
      method: 'get',
      url: endpoint,
    });
    return axiosResults.data;
  } catch (error) {
    throw convertAxiosError(error as AxiosError);
  }
}

export async function getClassroomFeelingsCheckins(classroomId: string, tableOverride?: string) {
  const endpoint = `${peekavilleBackendRoot}/moodboard/${classroomId}${tableOverride ? `/${tableOverride}` : ''}`;
  const auth = await getAuthorizationToken();
  try {
    const axiosResults = await axios({
      method: 'get',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
    });
    return axiosResults.data;
  } catch (error) {
    throw convertAxiosError(error as AxiosError);
  }
}

export async function getClassroomGameProgress(
  classroomId: string,
  tableOverride?: string,
): Promise<StudentProgressData[]> {
  const endpoint = `${peekavilleBackendRoot}/game-progress/${classroomId}${tableOverride ? `/${tableOverride}` : ''}`;
  const auth = await getAuthorizationToken();
  try {
    const axiosResults = await axios({
      method: 'get',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
    });
    return axiosResults.data;
  } catch (error) {
    throw convertAxiosError(error as AxiosError);
  }
}
