import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import PrimaryButton from './components/PrimaryButton/PrimaryButton';
import { Segment, VerticalSpacer } from './core/core';
import { getSchools, storeNewSchool } from './BackendInterface';
import SpinnerOverlay from './SpinnerOverlay';
import CreateNewSchool from './SchoolEditorCreateNew';
import utilityStyles from './SCSS/common/utilityStyles.module.scss';
import styles from './SCSS/Gallery.module.scss';
import segmentStyles from './SCSS/Segment.module.scss';
import { History } from 'history';
import { useLocation } from 'react-router-dom';
type Props = {
  history: History;
};

const SchoolEditor = (props: Props): JSX.Element => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [schools, setSchools] = useState(null);
  const location = useLocation();
  useEffect(() => {
    if (isLoaded) {
      return;
    }

    const fetchSchools = async () => {
      const refreshedSchools = await getSchools();
      setSchools(refreshedSchools.Items);
      setIsLoaded(true);
    };

    fetchSchools();
  }, [isLoaded]);

  if (!isLoaded) {
    return <SpinnerOverlay isShow={true} />;
  }

  if (location.pathname.includes('new')) {
    return (
      <CreateNewSchool
        initialModel={{
          name: 'Gordon School',
          district: 'posenRobbins',
          address: '14100 Harrison Ave',
          city: 'Posen',
          state: 'IL',
          country: 'US',
        }}
        onSubmit={onCreateNewSubmit}
      />
    );
  }

  return (
    <div className={styles.gallery}>
      <VerticalSpacer ems={2} />
      <Segment className={utilityStyles.line}>
        <div className={styles.titleBar}>
          <div className={segmentStyles.moduleTitle}>School Editor</div>
          <PrimaryButton
            onClick={(event) => {
              createNewSchool(event, props.history);
            }}
          >
            + New School
          </PrimaryButton>
        </div>
      </Segment>
      <div className={styles.galleryCards}>
        <Table
          style={{
            width: '80%',
          }}
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>District</th>
              <th>Name</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            {schools.map((school) => (
              <tr key={school.id}>
                <td>{school.id}</td>
                <td>{school.district}</td>
                <td>{school.name}</td>
                <td>{school.address}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

function createNewSchool(event, history) {
  event.preventDefault();
  history.push('/schoolEditor/new');
}

async function onCreateNewSubmit(model) {
  await storeNewSchool(model);
}

export default SchoolEditor;
