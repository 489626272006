import Spinner from 'react-spinkit';
import styles from './SCSS/SpinnerOverlay.module.scss';
import utilityStyles from './SCSS/common/utilityStyles.module.scss';

interface Props {
  isShow: boolean;
}

export const SpinnerOverlay = ({ isShow }: Props) =>
  !isShow ? null : (
    <div className={styles.spinnerWrapper}>
      <div className={`${utilityStyles.absCentre} vcenter ${styles.spinner}`}>
        <Spinner name='three-bounce' />
      </div>
    </div>
  );

export default SpinnerOverlay;
