import { useState } from 'react';
import cx from 'classnames';
import Spinner from 'react-spinkit';
import useSlideShowPointers from './useSlideShowPointers';
import styles from './SCSS/SlideShow.module.scss';
import { useHistory, useParams } from 'react-router-dom';

const SlideShow = (): JSX.Element | null => {
  const history = useHistory();
  const params = useParams();
  const [pointersLoadState, slideShowPointers] = useSlideShowPointers();
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  const onIframeLoaded = () => {
    // console.debug('%ciframeloaded...', 'background: blue, color: yellow');
    setIsIframeLoaded(true);
  };

  if (!params.id) {
    return null;
  }

  if (pointersLoadState === 'none') {
    return null;
  }

  if (pointersLoadState !== 'loaded') {
    return (
      <div className={styles.slideShowContainer}>
        <div className={styles.spinner}>
          <Spinner name='circle' />
        </div>
      </div>
    );
  }

  const slideShowUrl = slideShowPointers[params.id].url;

  console.debug(`%cSlideShow render`, 'background: blue; color: yellow');
  return (
    <div className={styles.slideShowContainer}>
      <div className={styles.goBack} onClick={() => history.go(-1)}>
        <i className='fa fa-chevron-left'></i>
      </div>
      {!isIframeLoaded && (
        <div className={styles.slideShowContainer}>
          <div className={styles.spinner}>
            <Spinner name='circle' />
          </div>
        </div>
      )}
      <iframe
        title='SlideShow'
        src={slideShowUrl}
        frameBorder='0'
        width='960'
        height='629'
        allowFullScreen={true}
        onLoad={() => onIframeLoaded()}
        className={cx({
          [`${styles.hidden}`]: !isIframeLoaded,
        })}
      ></iframe>
    </div>
  );
};

export default SlideShow;
