import configuration from './configuration.json';
import Hashids from 'hashids';
import { allCountriesDb, DialingInformation } from './CountryData';
import Documents from './Documents';
import { getStore } from './ApplicationState';
import { getCountryOriginBasedOnIp as getCountry } from './BackendInterface';
import {
  getCookie,
  getLocalStorage,
  hasPathBeforeLoginRedirect,
  hasPathBeforeSessionExpired,
  removeLocalStorage,
  restorePathBeforeLoginRedirect,
  restorePathBeforeSessionExpired,
  setCookie,
  setLocalStorage,
} from './aaa';
import { getJob } from './BackendInterface';
import { validateEmail as vm } from './core/core';
import { getMostRecentlyCreatedClassroom, setSelectedClassroom as setSelectedClassroomAction } from './Classrooms';
import { isElementaryStudent, isUserLoggedIn } from './UserManagementHelpers';
import { logger } from './error-tracker';
import { History, Location } from 'history';
import { FormikErrors } from 'formik';
import compassOutline from '@iconify/icons-eva/compass-outline';
import bookOpenOutline from '@iconify/icons-eva/book-open-outline';
import messageSquareOutline from '@iconify/icons-eva/message-square-outline';
import editOutline from '@iconify/icons-eva/edit-outline';
import flashOutline from '@iconify/icons-eva/flash-outline';
import { createUserPropertyAccessors, getSyncSource } from './RosteringUtilities';
import { LicenseLevel } from './UserManagement';
import { UserProfileType, ClassroomType, GetCountryOriginResult } from '../peekapak-types/DataProtocolTypes';
import { Module } from '../peekapak-types/LessonPlanTypes';
import { RouterLocationState } from './frontend-types';

export const LICENSE_EXPIRY_UPPER_LIMIT = Number.MAX_SAFE_INTEGER;
export const openUserEmailToHello = () => {
  window.location.href = 'mailto:hello@peekapak.com?subject=Help%20request%20from%20usability%20research%20website';
};
export const openUserEmailToHelloRequestTrial = () => {
  window.location.href = 'mailto:hello@peekapak.com?subject=Trial%20request';
};
export const msInADay = 86400000;
export const msInAWeek = msInADay * 7;
export function getUserEmailDomain(userProfile: { email: string }) {
  if (!userProfile || !userProfile.email) return '';
  return userProfile.email.split('@')[1];
}
export function getWeekRangeFromUnix(timestamp: number) {
  const d = new Date(timestamp);
  const startOfDay = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 3, 0, 0);
  const dayOfWeek = startOfDay.getDay();
  const weekRange = [startOfDay.getTime() - dayOfWeek * msInADay, startOfDay.getTime() + (7 - dayOfWeek) * msInADay];
  return weekRange;
}

export function getBeginingAndEndingOfWeekFromUnix(timestamp: number) {
  const d = new Date(timestamp);
  const startOfDay = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);
  const dayOfWeek = startOfDay.getDay();
  const weekRange = [
    startOfDay.getTime() - dayOfWeek * msInADay,
    startOfDay.getTime() + (6 - dayOfWeek) * msInADay + msInADay - 1,
  ];
  return weekRange;
}
export const getLetterGradeFromNumberGrade = (numberGrade: number) => {
  const mapping: Record<number, string> = {
    '-1': 'pk',
    0: 'k',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
    11: '11',
    12: '12',
  };
  return mapping[numberGrade];
};
export const getGradeDescriptionFromNumberGrade = (inputGrade: string | number) => {
  const grade = (() => {
    if (typeof inputGrade === 'string') return parseInt(inputGrade, 10);
    return inputGrade;
  })();
  const mapping = new Map([
    [-1, 'PreK'],
    [0, 'K'],
    [1, '1st'],
    [2, '2nd'],
    [3, '3rd'],
    [4, '4th'],
    [5, '5th'],
    [6, '6th'],
    [7, '7th'],
    [8, '8th'],
    [9, '9th'],
    [10, '10th'],
    [11, '11th'],
    [12, '12th'],
  ]);
  return mapping.get(grade);
};
export const getNumericGradeFromReadingLevel = (readingLevel: string) => {
  const rl = parseInt(readingLevel, 10);

  if (rl > 7 || rl < 1) {
    throw new Error('getNumericGradeFromReadingLevel(): readingLevel out of range');
  }

  return rl - 2;
};
export const getGradeCodeFromReadingLevel = (readingLevel: string) => {
  const rl = parseInt(readingLevel, 10);

  if (rl > 7 || rl < 1) {
    throw new Error('getGradeCodeFromReadingLevel(): readingLevel out of range');
  }

  const mapping = ['pk', 'k', '1', '2', '3', '4', '5'];
  return mapping[rl - 1];
};
export const getRecommendedReadingLevelFromGrade = (grade: number, language: string) => {
  let mapping: Record<number, number>;
  if (language === 'es') {
    mapping = {
      '-1': 0,
      0: 1,
      1: 1,
      2: 1,
      3: 1,
      4: 1,
      5: 1,
      6: 1,
      7: 1,
      8: 1,
      9: 1,
      10: 1,
      11: 1,
      12: 1,
    };
  } else if (language === 'en') {
    mapping = {
      '-1': 0,
      0: 1,
      1: 2,
      2: 2,
      3: 3,
      4: 4,
      5: 4,
      6: 4,
      7: 4,
      8: 4,
      9: 4,
      10: 4,
      11: 4,
      12: 4,
    };
  } else {
    throw new Error("Language is not 'en' or 'es'");
  }
  return mapping[grade];
};
export function convertToNumberIrreversibly(stringToConvert: string) {
  let acc = 0;

  for (let i = 0; i < stringToConvert.length; i++) {
    acc += stringToConvert.charCodeAt(i);
  }

  return acc;
}
export function convertToNumberArray(stringToConvert: string) {
  const newArray = [];

  for (let i = 0; i < stringToConvert.length; i++) {
    newArray.push(stringToConvert.charCodeAt(i));
  }

  return newArray;
}
export function convertBackToString(arrayOfIntegers: number[]) {
  let result = '';
  result = String.fromCharCode(...arrayOfIntegers);
  return result;
}
export function getUnlimitedHash(salt: string) {
  return new Hashids(salt);
}
export function getAllCharactersHash(salt: string) {
  return new Hashids(salt, 8);
}
const customSafeAlphabet = 'abcdefghijkmnpqrstuvwxyz23456789';
export function getUnambiguousCharactersHash(salt: string) {
  return new Hashids(salt, 8, customSafeAlphabet);
}

function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export function getNewParentUid(studentSid: string) {
  const salt = studentSid;
  const epochTime = new Date().getTime();
  const hashids = new Hashids(salt);
  const newHashid = hashids.encode(epochTime);
  return newHashid;
}
export function getNewStudentUid(teachersEmail: string, classroomName: string, studentsFirstName: string) {
  const salt = teachersEmail + classroomName;
  const epochTime = new Date().getTime();
  const hashids = new Hashids(salt);
  const randomMin = convertToNumberIrreversibly(studentsFirstName);
  const randomMax = Math.floor(randomMin * getRandomArbitrary(1.1, 13.3));
  const newHashid = hashids.encode(epochTime + getRandomInt(randomMin, randomMax));
  return newHashid;
}
//
// additionally encodes characters that are not delimiters but
// are nonetheless reserved in RFC 3986
export function fixedEncodeURIComponent(str: string) {
  return encodeURIComponent(str).replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16)}`);
}

export function encodeRFC3986URI(str: string) {
  return encodeURI(str)
    .replace(/%5B/g, '[')
    .replace(/%5D/g, ']')
    .replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);
}
export function parseQuery(qstr: string, _hash: string) {
  // in case there's nothing to do
  if (!qstr) {
    return {};
  }

  const query: Record<string, string> = {};
  const queryKeys = [];
  const queryValues = [];
  const a = qstr.substring(1).split('&');

  for (let i = 0; i < a.length; i++) {
    const b = a[i].split('=');
    queryKeys[i] = b[0];
    queryValues[i] = b[1];
  }

  for (let i = 0; i < a.length; i++) {
    query[decodeURIComponent(queryKeys[i])] = decodeURIComponent(queryValues[i]);
  }

  return query;
}
export function debounce(func: () => void, wait: number, immediate: boolean, ...args: any[]) {
  let timeout: number | undefined;
  return function () {
    const later = () => {
      timeout = undefined;
      if (!immediate) func.apply(args);
    };

    const callNow = immediate && !timeout;
    window.clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);
    if (callNow) func.apply(args);
  };
}
export function getNewDebounce<T extends any[]>(fn: (...args: T) => void, ...args: T) {
  let timeout: number | undefined;

  return () => {
    // If there's a timer, cancel it
    if (timeout !== undefined) {
      window.cancelAnimationFrame(timeout);
    }

    // Setup the new requestAnimationFrame()
    timeout = window.requestAnimationFrame(() => {
      fn(...args);
    });
  };
}
export function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}
export const getPossessiveForm = (name: string) => {
  if (name.toLowerCase().slice(-1) === 's') {
    return `${name}’`;
  }

  return `${name}’s`;
};

const getFormattedTime = (time: Date) =>
  time.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });

const dayMapping = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const monthMapping = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const getDescriptiveDateFromEpoch = (epochInput: number | string) => {
  let epoch;

  if (typeof epochInput === 'string') {
    epoch = parseInt(epochInput, 10);
  } else {
    epoch = epochInput;
  }

  const timestamp = new Date(epoch);
  const now = new Date();
  const oneMinutePastMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 1, 0);
  let distance = Math.abs(timestamp.valueOf() - oneMinutePastMidnight.valueOf());
  const twenty4hours = 60 * 60 * 24 * 1000;

  if (distance > 0 && distance < twenty4hours) {
    return `today at ${getFormattedTime(timestamp)}`;
  }

  const yesterdayOneMinutePastMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 1, 0);
  distance = Math.abs(timestamp.valueOf() - yesterdayOneMinutePastMidnight.valueOf());

  if (distance > 0 && distance < twenty4hours) {
    return `yesterday at ${getFormattedTime(timestamp)}`;
  }

  const dayOfWeek = dayMapping[timestamp.getDay()];
  const month = monthMapping[timestamp.getMonth()];
  const pastSunday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay(), 0, 1, 0);
  distance = Math.abs(timestamp.valueOf() - pastSunday.valueOf());
  const weekLong = 60 * 60 * 24 * 7 * 1000;

  if (distance > 0 && distance < weekLong) {
    return `${dayOfWeek} at ${getFormattedTime(timestamp)}`;
  }

  const lastSunday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7 - now.getDay(), 0, 1, 0);
  distance = Math.abs(timestamp.valueOf() - lastSunday.valueOf());
  const twoWeeksLong = 60 * 60 * 24 * 14 * 1000;

  if (distance > 0 && distance < twoWeeksLong) {
    return `last ${dayOfWeek}, ${month} ${timestamp.getDate()}`;
  }

  const beginningOfYear = new Date(now.getFullYear(), 1, 1, 0, 1, 0);
  distance = Math.abs(timestamp.valueOf() - beginningOfYear.valueOf());
  const monthLong = 60 * 60 * 24 * 30 * 1000;

  if (distance > 0 && distance < monthLong) {
    return `${dayOfWeek}, ${month} ${timestamp.getDate()}`;
  }

  return `${month} ${timestamp.getDate()}, ${timestamp.getFullYear()}`;
};
export function tryParseJSON(jsonString: string) {
  try {
    const o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {
    return false;
  }

  return false;
}
// get time elements from unix Time format
export function getReadableUnixTimeElements(unixTime: number): [number, number, number, number, number, number] {
  const a = new Date(unixTime);
  const year = a.getFullYear();
  const monthValue = a.getMonth();
  const dateValue = a.getDate();
  const hour = a.getHours();
  const min = a.getMinutes();
  const sec = a.getSeconds();
  return [year, monthValue, dateValue, hour, min, sec];
}
// convert Unix Time to readable Ansi format: YYYY-MM-DD HH:MM:SS
export function getReadableUnixTimeAnsi(unixTime: number) {
  const [year, month, date, hour, min, sec] = getReadableUnixTimeElements(unixTime);
  return `${year}-${(month + 1).toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}
    ${hour.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
}
// convert Unix Time to readable Ansi format with date only
export function getReadableUnixTimeDateOnly(unixTime: number) {
  const [year, month, date, _hour, _min, _sec] = getReadableUnixTimeElements(unixTime);
  return `${year}-${(month + 1).toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}`;
}
export function getReadableUnixTimeMonthDateOnly(unixTime: number) {
  const [_year, month, date, _hour, _min, _sec] = getReadableUnixTimeElements(unixTime);
  return `${monthMapping[month]} ${date}`;
}
// convert Unix Time to readable format with time only
export function getReadableUnixTimeTimeOnly(unixTime: number) {
  const [_year, _month, _date, hour, min, sec] = getReadableUnixTimeElements(unixTime);
  return `${hour}:${min}:${sec}`;
}
export function getMonthName(date: number | Date) {
  if (date instanceof Date) {
    return monthMapping[date.getMonth()];
  } else {
    const dateAsDate = new Date(date);
    return monthMapping[dateAsDate.getMonth()];
  }
}
export function searchInArray(nameKey: string, myArray: DialingInformation[]) {
  for (let i = 0; i < myArray.length; i += 1) {
    if (myArray[i].name === nameKey) {
      return myArray[i];
    }
  }

  return {
    name: 'United States',
    iso2: 'us',
    dialCode: '1',
    format: '+. (...) ...-....',
  };
}

const compareCountryCode = (number: string, dialCode: string) => {
  for (let i = 0; i < dialCode.length; i += 1) {
    if (number[i] !== dialCode[i]) {
      for (let j = 1; j < 6; j += 1) {
        if (isNaN(Number(number[j])) || number[j] === ' ') {
          return j;
        }
      }
    }
  }

  return 0;
};

export function formatTelephoneNumber(number: string, country: string) {
  let Newnumber = '';

  if (country === 'null') {
    country = 'United States';
  }

  const DialCode = searchInArray(country, allCountriesDb).dialCode || '';

  if (!number) {
    return `+${DialCode}`;
  }

  const pattern = searchInArray(country, allCountriesDb).format || '..................';
  const indexOfDialCodeEnd = compareCountryCode(number, `+${DialCode}`);

  if (indexOfDialCodeEnd === 0 && pattern.length <= number.length) {
    return number;
  }

  if (number[0] === '+') {
    if (indexOfDialCodeEnd !== 0) {
      Newnumber = DialCode + number.slice(indexOfDialCodeEnd).replace(/\D/g, '');
    } else {
      Newnumber = number.replace(/\D+/g, '');
    }
  } else {
    Newnumber = DialCode + number;
  }

  let j = 0;
  let formattedNum = '';

  for (let i = 0; j < Newnumber.length; i += 1) {
    if (pattern[i] === '.') {
      formattedNum += Newnumber[j];
      j += 1;
    } else if (i < pattern.length) {
      formattedNum += pattern[i];
    } else {
      j += 1;
    }
  }

  return formattedNum;
}
export function downloadCSVFile(fileName: string, CSVData: string) {
  const encodedUri = encodeURI(CSVData);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}
export function getInitial(fulltext: string) {
  const wordArray = fulltext.split(' ');
  let output = '';
  wordArray.forEach((entry) => {
    output += entry.charAt(0);
  });
  return output;
}
export function getClassroomDescription(classroom: ClassroomType) {
  const name = classroom.className;
  return `${name} / ${getGradeDescriptionFromNumberGrade(classroom.grade)}`;
}
export interface StaticConfiguration {
  cognito: CognitoConfiguration;
  google: GoogleConfiguration;
  clever: CleverConfiguration;
  stripe: StripeConfiguration;
  intercom: IntercomConfiguration;
  mixpanel: MixpanelConfiguration;
  sentry: SentryConfiguration;
}
export interface CognitoConfiguration {
  region: string;
  userPool: string;
  identityPool: string;
  clientId: string;
  hostedUICognitoDomain: string;
  hostedUISignInCallbackPath: string;
  hostedUISignOutCallbackPath: string;
}
export interface GoogleConfiguration {
  clientId: string;
  discoveryDocs: string[];
  scope: string;
  placesApiKey: string;
}
export interface CleverConfiguration {
  authorizeUrlBase: string;
  clientId: string;
  scope: string;
  redirectUriPath: string;
}
export interface StripeConfiguration {
  apiKey: string;
}
export interface IntercomConfiguration {
  apiKey: string;
}
export interface MixpanelConfiguration {
  apiKey: string;
}
export interface SentryConfiguration {
  environment: string;
  tracesSampleRate: number;
  replaysSessionSampleRate: number;
  replaysOnErrorSampleRate: number;
}

export function getConfiguration(configurationKey: keyof StaticConfiguration) {
  if (!isConfigurationKeyExists()) {
    throw new Error(`getConfiguration: configuration file does not have an entry for "${configurationKey}"`);
  }

  if (import.meta.env.REACT_TEST_PROD_API) {
    return configuration.prod[configurationKey];
  } else if (import.meta.env.MODE === 'development' || import.meta.env.REACT_USE_DEV_API) {
    return configuration.dev[configurationKey];
  } else {
    return configuration.prod[configurationKey];
  }

  function isConfigurationKeyExists() {
    return (
      Object.prototype.hasOwnProperty.call(configuration.dev, configurationKey) ||
      Object.prototype.hasOwnProperty.call(configuration.prod, configurationKey)
    );
  }
}
export const getModuleIcon = (type: string) => {
  if (type === 'Assessment' || type === 'multipleChoice') return compassOutline;
  if (type === 'Story' || type === 'Featured Story') return bookOpenOutline;
  if (type === 'Connect') return messageSquareOutline;
  if (type === 'Challenge') return flashOutline;
  if (type === 'Activity' || type === 'Story Writing' || type === 'questionAnswer') return editOutline;
};
export function makeRoot(originalUri: string) {
  return originalUri.startsWith('/') ? originalUri : `/${originalUri}`;
}
export function getUsersEmail() {
  if (isUserLoggedIn()) {
    return getStore().getState().user.userProfile?.email;
  } else {
    return null;
  }
}

export function getUsersMessageMark() {
  if (isUserLoggedIn()) {
    return getStore().getState().user.userProfile?.messageMark;
  } else {
    return null;
  }
}

export function getUsersId() {
  if (isUserLoggedIn()) {
    return getStore().getState().user.userProfile?.userId;
  } else {
    return null;
  }
}
export function getUsersKeyRing() {
  if (isUserLoggedIn()) {
    return getStore().getState().user.userProfile?.keyRing;
  } else {
    return null;
  }
}
export function getUsersEnableContentTags(): string[] | null {
  if (isUserLoggedIn()) {
    return getStore()?.getState()?.user?.userProfile?.enableContentTags;
  } else {
    return null;
  }
}
export function getUsersDisableContentTags(): string[] | null {
  if (isUserLoggedIn()) {
    return getStore()?.getState()?.user?.userProfile?.disableContentTags;
  } else {
    return null;
  }
}
export function getLicenseLevel() {
  if (!isUserLoggedIn()) {
    return null;
  }

  const { user } = getStore().getState();
  return user.userProfile?.licenseLevel;
}
export function getLicenseExpires() {
  if (!isUserLoggedIn()) {
    return null;
  }

  const { user } = getStore().getState();
  return user.userProfile?.licenseExpires;
}
export function isUsersTrialAlreadyUsed() {
  if (!isUserLoggedIn()) {
    return null;
  }

  const { user } = getStore().getState();
  return user.userProfile?.flags && user.userProfile?.flags.isLicenseDoneTrial;
}
export function isUsersClassroomTrialAlreadyUsed() {
  if (!isUserLoggedIn()) {
    return null;
  }

  const { user } = getStore().getState();
  // unfortunately, we wrote some bad code and had stored
  // the trial activation state in the root of the user profile
  // as well as in the flags
  if (user.userProfile && user.userProfile.isActivateClassroomTrial) return true;
  if (user.userProfile?.flags && user.userProfile.flags.isDoneClassroomTrial) return true;
  return false;
}
// check if user has any of the specified keys in array keyChecks
export function isUserHasAnyKeys(keyChecks: Array<string>) {
  if (!isUserLoggedIn()) return null;
  const { user } = getStore().getState();
  if (!user.userProfile?.keyRing) return false;

  for (let i = 0; i < user.userProfile.keyRing.length; ++i) {
    if (keyChecks.indexOf(user.userProfile.keyRing[i]) !== -1) return true;
  }

  return false;
}
export function isUserMissingSchool() {
  if (!isUserLoggedIn()) {
    return false;
  }

  const { user } = getStore().getState();
  return !user.userProfile?.schoolName;
}
export function isUserHasKey(keyCheck: string) {
  return isUserHasKeys(keyCheck) || isUserHasKeys('KEY_MASTER_KEY');
}
export function isUserHasKeys(keyCheck: string | Array<string>) {
  if (!isUserLoggedIn()) {
    return null;
  }

  const { user } = getStore().getState();
  if (!user.userProfile?.keyRing) return false;

  if (typeof keyCheck === 'string' || keyCheck instanceof String) {
    return user.userProfile?.keyRing && user.userProfile?.keyRing.indexOf(keyCheck as string) !== -1;
  }

  if (!keyCheck || keyCheck.length === 0) {
    return true;
  }

  for (let i = 0; i < keyCheck.length; ++i) {
    if (!user.userProfile?.keyRing.includes(keyCheck[i])) {
      return false;
    }
  }

  return true;
}
export const canShouldUserAutoSync = (): boolean => {
  if (!isUserLoggedIn()) {
    return false;
  }

  const { user } = getStore().getState();

  if (!user.userProfile?.providerName) {
    return false;
  }

  if (user.userProfile?.flags?.isSkipAutoSync) {
    return false;
  }

  if (
    user.userProfile.licenseLevel === LicenseLevel.districtAdministrator ||
    user.userProfile.licenseLevel === LicenseLevel.schoolAdministrator
  ) {
    return false;
  }

  try {
    const syncSource = getSyncSource(user.userProfile.providerName);
    const propertyAccessor = createUserPropertyAccessors(syncSource, user);

    return !!propertyAccessor.getApiAccessToken() && !!propertyAccessor.getApiUserId();
  } catch (error) {
    if (error instanceof Error && error.message === 'Import source Google is not recognized') {
      console.warn(error.message);
      return false;
    }

    throw error;
  }
};

export const startPlatformAfterSuccessfulLogin = (location: Location<RouterLocationState>, history: History) => {
  if (location.state && location.state.nextPathname) {
    history.replace(location.state.nextPathname);
  } else if (location.state && location.state.from) {
    history.replace(location.state.from.pathname);
  } else if (hasPathBeforeLoginRedirect()) {
    restorePathBeforeLoginRedirect();
  } else if (hasPathBeforeSessionExpired()) {
    restorePathBeforeSessionExpired();
  } else {
    const user = getStore().getState().user.userProfile;

    if (user) {
      return sendUserToRoleHomePage(user, history, true, true);
    }

    return history.push('/library');
  }
};
export function debugPauseExecution(seconds = 5) {
  return new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000);
  });
}
export function getMyOwnUri() {
  return `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
}
export function setCleverOAuthResumePath(path: string) {
  return setLocalStorage('CleverOAuthResumePath', path);
}
export function getCleverOAuthResumePath() {
  return getLocalStorage('CleverOAuthResumePath');
}
export function initiateCleverLogin(callbackUri: string | null | undefined, resumePath?: string) {
  const cleverConfig: CleverConfiguration = getConfiguration('clever') as CleverConfiguration;
  const rememberState = getAllCharactersHash(Date.now().toString()).encode(Date.now());
  setLocalStorage('CleverOAuthState', rememberState);
  let redirectUri;

  if (callbackUri) {
    redirectUri = `${window.location.protocol}//${window.location.host}${callbackUri}`;
  } else {
    redirectUri = `${window.location.protocol}//${window.location.host}${cleverConfig.redirectUriPath}`;
  }

  if (resumePath) {
    setCleverOAuthResumePath(resumePath);
  }

  const queryString = `?response_type=code&client_id=${cleverConfig.clientId}&state=${rememberState}&redirect_uri=${fixedEncodeURIComponent(redirectUri)}`;
  const authorizeUrl = `${cleverConfig.authorizeUrlBase}${queryString}`;
  window.location.replace(authorizeUrl);
}
export function isCleverLoginStateValid(passedInState: string) {
  const storedState = getLocalStorage('CleverOAuthState');

  if (storedState) {
    removeLocalStorage('CleverOAuthState');
  }

  return storedState === passedInState;
}
export function getCleverId(cleverAccessToken: string): Promise<{ data: { id: string } }> {
  return new Promise((resolve, reject) => {
    const authorizationString = `Bearer ${cleverAccessToken}`;
    fetch('https://api.clever.com/v3.0/me', {
      method: 'get',
      headers: {
        Authorization: authorizationString,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        return resolve(json);
      })
      .catch((error) => reject(error));
  });
}
export function getCleverProfile(cleverId: string, cleverAccessToken: string) {
  return new Promise((resolve, reject) => {
    const authorizationString = `Bearer ${cleverAccessToken}`;
    fetch(`https://api.clever.com/v3.0/users/${cleverId}`, {
      method: 'get',
      headers: {
        Authorization: authorizationString,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        return resolve(json);
      })
      .catch((error) => reject(error));
  });
}
export function canAccessPeekaville(licenseLevel: string, licenseExpires: number) {
  // copied from mypeekaville.com
  const allowedLicenses = [
    'CLASSROOM',
    'RENEWED_CLASSROOM',
    'DEVELOPER',
    'PEEKAPAK_SALES',
    'AUTHOR',
    'PUBLISHER',
    'CLASSROOM_ADMINISTRATOR',
    'DISTRICT_ADMINISTRATOR',
    'SCHOOL_ADMINISTRATOR',
  ];
  return allowedLicenses.includes(licenseLevel) && Date.now() < licenseExpires;
}
export function canUpgrade(licenseLevel: string) {
  if (canUpgradeToPro(licenseLevel) || canUpgradeToClassroom(licenseLevel)) {
    return true;
  }

  return false;
}
export function alreadyHighestLicense(licenseLevel: string) {
  return !canUpgradeToClassroom(licenseLevel);
}
export function canUpgradeToPro(licenseLevel: string) {
  const applicableLicenses = [
    'BASIC',
    'FREEMIUM',
    'TRIAL',
    'TRIAL_CLASSROOM',
    'EXPIRED_TRIAL',
    'EXPIRED_PRO',
    'EXPIRED_CLASSROOM',
    'EXPIRED_ADMINISTRATOR',
    'EXPIRED_DEVELOPER',
    'EXPIRED_HOMESCHOOL',
  ];
  return applicableLicenses.includes(licenseLevel);
}
export function canUpgradeToClassroom(licenseLevel: string) {
  const applicableLicenses = [
    'BASIC',
    'FREEMIUM',
    'TRIAL',
    'TRIAL_CLASSROOM',
    'PRO',
    'EXPIRED_TRIAL',
    'EXPIRED_PRO',
    'EXPIRED_CLASSROOM',
    'EXPIRED_ADMINISTRATOR',
    'EXPIRED_DEVELOPER',
    'EXPIRED_HOMESCHOOL',
  ];
  return applicableLicenses.includes(licenseLevel);
}
export function flattenDeep(arr1: Array<any>): Array<any> {
  return arr1.reduce((acc, val) => (Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val)), []);
}
export function isElementVisible(elementId: string) {
  const el = document.getElementById(elementId);

  if (!el) {
    return false;
  }

  if (el.offsetParent === null) {
    return false;
  }

  const elsStyle = window.getComputedStyle(el);

  if (elsStyle['display'] === 'none') {
    return false;
  }

  return true;
}
export function noWhiteSpace(strings: TemplateStringsArray, ...placeholders: string[]) {
  // Build the string as normal, combining all the strings and placeholders:
  const withSpace = strings.reduce((result, string, i) => result + placeholders[i - 1] + string);
  const withoutSpace = withSpace.replace(/\s\s+/g, ' ');
  return withoutSpace;
}

type VisitorOriginDataCookie = GetCountryOriginResult & {
  createdAt: number;
};

export async function getVisitorOriginBasedOnIp(): Promise<GetCountryOriginResult> {
  const existingRecordJSON = getCookie('peekapak.visitorOrigin');
  try {
    const existingRecord: VisitorOriginDataCookie = JSON.parse(existingRecordJSON);

    if (existingRecord.createdAt) {
      if (Date.now() - existingRecord.createdAt < 1000 * 3600 * 24) {
        return existingRecord;
      }
    }
  } catch (error) {
    const warningMessage = `Error detected in getting cookie content, but will call backend to recover: ${(error as Error).message}`;
    logger.logMessage(warningMessage);
    console.warn(warningMessage);
  }

  try {
    const data: GetCountryOriginResult = await getCountry();
    const persist = { ...data, createdAt: Date.now() };
    setCookie('peekapak.visitorOrigin', JSON.stringify(persist));
    return data;
  } catch (_error) {
    const warningMessage = `Error trying to get visitor origin, defaulting to US: ${JSON.stringify(_error, null, 2)}`;
    logger.logMessage(warningMessage);
    console.warn(warningMessage);
    return {
      country_code: 'US',
      country: 'United States',
      region_code: 'Unknown',
      region: 'Unknown',
      sourceIp: 'Unknown',
    };
  }
}

export function getCountryOriginBasedOnIp(): Promise<string> {
  return new Promise((resolve, reject) => {
    const existingRecordJSON = getCookie('peekapak.countryOrigin');

    try {
      const existingRecord = JSON.parse(existingRecordJSON);

      if (existingRecord.createdAt) {
        if (Date.now() - existingRecord.createdAt < 1000 * 3600 * 24) {
          return resolve(existingRecord.country);
        }
      }
    } catch (error) {
      console.warn(
        `Error detected in getting cookie content, but will call backend to recover: ${(error as Error).message}`,
      );
    }

    getCountry()
      .then((data) => {
        const persist = { ...data, createdAt: Date.now() };
        setCookie('peekapak.countryOrigin', JSON.stringify(persist));
        return resolve(data.country);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}
export function setSelectedClassroom(index: number) {
  getStore().dispatch(setSelectedClassroomAction(index));
  storeSelectedClassroom(index);
}
export function storeSelectedClassroom(index: number) {
  setCookie('peekapak.lastSelectedClassroom', index.toString());
}
export function getLastSelectedClassroom(classrooms: ClassroomType[]) {
  const lastSelectedClassroomCookie = getCookie('peekapak.lastSelectedClassroom');

  if (lastSelectedClassroomCookie) {
    const index = parseInt(lastSelectedClassroomCookie, 10);

    if (index < classrooms.length - 1) {
      return index;
    }
  }

  return getMostRecentlyCreatedClassroom(classrooms);
}
export const GuidedReadingScheme: Record<string, { index: number; shortForm: string; longForm: string }> = {
  fountasAndPinnell: {
    index: 0,
    shortForm: 'F&P',
    longForm: 'Fountas and Pinnell',
  },
  readingRecovery: {
    index: 1,
    shortForm: 'RR',
    longForm: 'Reading Recovery',
  },
  dra: {
    index: 2,
    shortForm: 'DRA',
    longForm: 'Developmental Reading Assessment',
  },
  pmBenchmark: {
    index: 3,
    shortForm: 'PMB',
    longForm: 'PM Benchmark',
  },
  peekapakSpanish: {
    index: 4,
    shortForm: 'ES',
    longForm: 'Spanish',
  },
  peekapakEnglish: {
    index: 5,
    shortForm: 'EN',
    longForm: 'English',
  },
};
const GuidedReadingRangeMapping = [
  ['E - F', '7 - 11', '8 - 12', '7 - 12'],
  ['G - H', '11 - 14', '12 - 14', '11 - 14'],
  ['I - J', '15 - 16', '16 - 18', '15 - 17'],
  ['K - L', '17 - 18', '18 - 20', '17 - 20'],
  ['S - T', 'N/A', '40', '28-29'],
];
const GuidedReadingMapping: Record<string, string[][]> = {
  'Self-Regulation': [
    ['E/F', 'Level 8/9', 'Level 8', 'Level 7/8', '0', '0'],
    ['G/H', 'Level 13', 'Level 14', 'Level 13/14', '1', '1'],
    ['I/J', 'Level 16', 'Level 16', 'Level 15/16', '1', '2'],
    ['K/L', 'Level 18', 'Level 18', 'Level 17/18', '1', '3'],
    ['S/T', 'N/A', 'Level 40', 'Level 28/29', '1', '4'],
  ],
  'Self-RegulationMena': [
    ['E/F', 'Level 8/9', 'Level 8', 'Level 7/8', '0', '0'],
    ['E/F', 'Level 8/9', 'Level 8', 'Level 7/8', '0', '0'],
    ['E/F', 'Level 8/9', 'Level 8', 'Level 7/8', '0', '0'],
    ['E/F', 'Level 8/9', 'Level 8', 'Level 7/8', '0', '0'],
    ['E/F', 'Level 8/9', 'Level 8', 'Level 7/8', '0', '0'],
  ],
  Respect: [
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['G/H', 'Level 13', 'Level 14', 'Level 13/14', '1', '1'],
    ['I/J', 'Level 17', 'Level 18', 'Level 17', '1', '2'],
    ['K/L', 'Level 18', 'Level 19', 'Level 18', '1', '3'],
    ['S/T', 'N/A', 'Level 40', 'Level 28/29', '1', '4'],
  ],
  RespectMena: [
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
  ],
  Gratitude: [
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['G/H', 'Level 11/12', 'Level 12', 'Level 11/12', '1', '1'],
    ['I/J', 'Level 16', 'Level 16', 'Level 15/16', '1', '2'],
    ['K/L', 'Level 17', 'Level 18', 'Level 18', '1', '3'],
    ['S/T', 'N/A', 'Level 40', 'Level 28/29', '1', '4'],
  ],
  GratitudeMena: [
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
  ],
  Kindness: [
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['G/H', 'Level 11/12', 'Level 12', 'Level 11/12', '1', '1'],
    ['I/J', 'Level 17', 'Level 18', 'Level 17', '1', '2'],
    ['K/L', 'Level 18', 'Level 19', 'Level 18', '1', '3'],
    ['S/T', 'N/A', 'Level 40', 'Level 28/29', '1', '4'],
  ],
  KindnessMena: [
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
  ],
  Perseverance: [
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['G/H', 'Level 11/12', 'Level 12', 'Level 11/12', '1', '1'],
    ['I/J', 'Level 15/16', 'Level 16', 'Level 15/16', '1', '2'],
    ['K/L', 'Level 18', 'Level 20', 'Level 19/20', '1', '3'],
    ['S/T', 'N/A', 'Level 40', 'Level 28/29', '1', '4'],
  ],
  PerseveranceMena: [
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
  ],
  Teamwork: [
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['G/H', 'Level 13', 'Level 14', 'Level 13/14', '1', '1'],
    ['I/J', 'Level 17', 'Level 18', 'Level 17', '1', '2'],
    ['K/L', 'Level 18', 'Level 20', 'Level 19/20', '1', '3'],
    ['S/T', 'N/A', 'Level 40', 'Level 28/29', '1', '4'],
  ],
  TeamworkMena: [
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
  ],
  Empathy: [
    ['E/F', 'Level 7/8', 'Level 8', 'Level 7/8', '0', '0'],
    ['G/H', 'Level 11/12', 'Level 12', 'Level 11/12', '1', '1'],
    ['I/J', 'Level 16', 'Level 16', 'Level 15/16', '1', '2'],
    ['K/L', 'Level 18', 'Level 18', 'Level 17/18', '1', '3'],
    ['S/T', 'N/A', 'Level 40', 'Level 28/29', '1', '4'],
  ],
  EmpathyMena: [
    ['E/F', 'Level 7/8', 'Level 8', 'Level 7/8', '0', '0'],
    ['E/F', 'Level 7/8', 'Level 8', 'Level 7/8', '0', '0'],
    ['E/F', 'Level 7/8', 'Level 8', 'Level 7/8', '0', '0'],
    ['E/F', 'Level 7/8', 'Level 8', 'Level 7/8', '0', '0'],
    ['E/F', 'Level 7/8', 'Level 8', 'Level 7/8', '0', '0'],
  ],
  Honesty: [
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['G/H', 'Level 13', 'Level 14', 'Level 13/14', '1', '1'],
    ['I/J', 'Level 15/16', 'Level 16', 'Level 15/16', '1', '2'],
    ['K/L', 'Level 18', 'Level 18', 'Level 17/18', '1', '3'],
    ['S/T', 'N/A', 'Level 40', 'Level 28/29', '1', '4'],
  ],
  HonestyMena: [
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
    ['E/F', 'Level 9/10', 'Level 10', 'Level 9/10', '0', '0'],
  ],
  Optimism: [
    ['F/G', 'Level 11', 'Level 12', 'Level 11/12', '0', '0'],
    ['G/H', 'Level 14', 'Level 14', 'Level 13/14', '1', '1'],
    ['I/J', 'Level 17', 'Level 18', 'Level 17', '1', '2'],
    ['K/L', 'Level 18', 'Level 20', 'Level 19/20', '1', '3'],
    ['S/T', 'N/A', 'Level 40', 'Level 28/29', '1', '4'],
  ],
  OptimismMena: [
    ['F/G', 'Level 11', 'Level 12', 'Level 11/12', '0', '0'],
    ['F/G', 'Level 11', 'Level 12', 'Level 11/12', '0', '0'],
    ['F/G', 'Level 11', 'Level 12', 'Level 11/12', '0', '0'],
    ['F/G', 'Level 11', 'Level 12', 'Level 11/12', '0', '0'],
    ['F/G', 'Level 11', 'Level 12', 'Level 11/12', '0', '0'],
  ],
  Courage: [
    ['E/F', 'Level 7/8', 'Level 8', 'Level 7/8', '0', '0'],
    ['G/H', 'Level 13', 'Level 14', 'Level 13/14', '1', '1'],
    ['I/J', 'Level 15/16', 'Level 16', 'Level 15/16', '1', '2'],
    ['K/L', 'Level 17', 'Level 18', 'Level 17/18', '1', '3'],
    ['S/T', 'N/A', 'Level 40', 'Level 28/29', '1', '4'],
  ],
  CourageMena: [
    ['E/F', 'Level 7/8', 'Level 8', 'Level 7/8', '0', '0'],
    ['E/F', 'Level 7/8', 'Level 8', 'Level 7/8', '0', '0'],
    ['E/F', 'Level 7/8', 'Level 8', 'Level 7/8', '0', '0'],
    ['E/F', 'Level 7/8', 'Level 8', 'Level 7/8', '0', '0'],
    ['E/F', 'Level 7/8', 'Level 8', 'Level 7/8', '0', '0'],
  ],
  'Pandemic-SEL': [
    ['Level 1', 'Level 1', 'Level 1', 'Level 1', 'Level 1', 'Level 1'],
    ['Level 1', 'Level 1', 'Level 1', 'Level 1', 'Level 1', 'Level 1'],
    ['Level 2', 'Level 2', 'Level 2', 'Level 2', 'Level 2', 'Level 2'],
    ['Level 2', 'Level 2', 'Level 2', 'Level 2', 'Level 2', 'Level 2'],
  ],
  Friendship: [
    ['E/F', 'Level 8/9', 'Level 8', 'Level 7/8', '0', '0'],
    ['G/H', 'Level 13', 'Level 14', 'Level 13/14', '1', '1'],
    ['I/J', 'Level 16', 'Level 16', 'Level 15/16', '2', '2'],
    ['K/L', 'Level 17', 'Level 18', 'Level 17/18', '1', '3'],
    ['S/T', 'N/A', 'Level 40', 'Level 28/29', '1', '4'],
  ],
  Imagination: [
    ['E/F', 'Level 8/9', 'Level 8', 'Level 7/8', '0', '0'],
    ['G/H', 'Level 13', 'Level 14', 'Level 13/14', '1', '1'],
    ['I/J', 'Level 16', 'Level 16', 'Level 15/16', '2', '2'],
    ['K/L', 'Level 17', 'Level 18', 'Level 17/18', '1', '3'],
    ['S/T', 'N/A', 'Level 40', 'Level 28/29', '1', '4'],
  ],
  'Problem-Solving': [
    ['E/F', 'Level 8/9', 'Level 8', 'Level 7/8', '0', '0'],
    ['G/H', 'Level 13', 'Level 14', 'Level 13/14', '1', '1'],
    ['I/J', 'Level 16', 'Level 16', 'Level 15/16', '2', '2'],
    ['I/J', 'Level 16', 'Level 16', 'Level 15/16', '2', '2'],
  ],
  'Math-Anxiety': [
    ['K/L', 'Level 17', 'Level 18', 'Level 17/18', '1', '3'],
    ['K/L', 'Level 17', 'Level 18', 'Level 17/18', '1', '3'],
    ['K/L', 'Level 17', 'Level 18', 'Level 17/18', '1', '3'],
    ['K/L', 'Level 17', 'Level 18', 'Level 17/18', '1', '3'],
    ['S/T', 'N/A', 'Level 40', 'Level 28/29', '1', '4'],
  ],
  'Celebrating-Differences': [
    ['E/F', 'Level 7/8', 'Level 8', 'Level 7/8', '0', '0'],
    ['G/H', 'Level 13', 'Level 14', 'Level 13/14', '1', '1'],
    ['I/J', 'Level 15/16', 'Level 16', 'Level 15/16', '1', '2'],
    ['K/L', 'Level 17', 'Level 18', 'Level 17/18', '1', '3'],
    ['S/T', 'N/A', 'Level 40', 'Level 28/29', '1', '4'],
  ],
};
const EnglishReadingLevelsMap: Record<string, number[]> = {
  'Self-Regulation': [0, 1, 2, 3, 4],
  'Self-RegulationMena': [0],
  Respect: [0, 1, 2, 3, 4],
  RespectMena: [0],
  Gratitude: [0, 1, 2, 3, 4],
  GratitudeMena: [0],
  Kindness: [0, 1, 2, 3, 4],
  KindnessMena: [0],
  Perseverance: [0, 1, 2, 3, 4],
  PerseveranceMena: [0],
  Teamwork: [0, 1, 2, 3, 4],
  TeamworkMena: [0],
  Empathy: [0, 1, 2, 3, 4],
  EmpathyMena: [0],
  Honesty: [0, 1, 2, 3, 4],
  HonestyMena: [0],
  Optimism: [0, 1, 2, 3, 4],
  OptimismMena: [0],
  Courage: [0, 1, 2, 3, 4],
  CourageMena: [0],
  'Pandemic-SEL': [0, 2],
  Friendship: [0, 1, 2, 3, 4],
  Imagination: [0, 1, 2, 3, 4],
  'Problem-Solving': [0, 1, 2],
  'Math-Anxiety': [3, 4],
  'Celebrating-Differences': [0, 1, 2, 3, 4],
};
const SpanishReadingLevelsMap: Record<string, number[]> = {
  'Self-Regulation': [0, 1],
  'Self-RegulationMena': [],
  Respect: [0, 1],
  RespectMena: [],
  Gratitude: [0, 1],
  GratitudeMena: [],
  Kindness: [0, 1],
  KindnessMena: [],
  Perseverance: [0, 1],
  PerseveranceMena: [],
  Teamwork: [0, 1],
  TeamworkMena: [],
  Empathy: [0, 1],
  EmpathyMena: [],
  Honesty: [0, 1],
  HonestyMena: [],
  Optimism: [0, 1],
  OptimismMena: [],
  Courage: [0, 1],
  CourageMena: [],
  'Pandemic-SEL': [1, 2],
  Friendship: [],
  Imagination: [],
  'Problem-Solving': [],
  'Math-Anxiety': [],
  'Celebrating-Differences': [],
};
export function getEnglishReadingLevels(unitName: string) {
  return EnglishReadingLevelsMap[unitName];
}
export function getSpanishReadingLevels(unitName: string) {
  return SpanishReadingLevelsMap[unitName];
}
export function getGuidedReadingLevelRange(readingLevel: number, scheme: string): string {
  return `${GuidedReadingScheme[scheme].longForm} ${GuidedReadingRangeMapping[readingLevel][GuidedReadingScheme[scheme].index]}`;
}
export function getGuidedReadingLevel(unitName: string, readingLevel: number, scheme: string): string {
  return GuidedReadingMapping[unitName][readingLevel][GuidedReadingScheme[scheme].index];
}
export function getUserFriendlyErrorMessage(error: { message?: string }) {
  if (!error.message) {
    return error.toString();
  }

  const originalMessage = error.message;

  if (originalMessage.includes('UserMigration failed')) {
    return 'Incorrect username or password.';
  } else if (originalMessage.includes('Failed to fetch')) {
    return 'There was a problem that occurred on the server preventing login';
  } else if (originalMessage.includes('User is not authorized to get auth details')) {
    logger.logException(
      new Error(noWhiteSpace`Attempt to login using username \
    and password, but external provider account exists`),
    );
    return noWhiteSpace`An account already exists that was created from an \
    external provider, such as Google or Clever. Please try using an alternative sign \
    in method instead`;
  } else if (originalMessage.includes('Student users not allowed on this site')) {
    return noWhiteSpace`Looks like you're a student. Students should login at \
    mypeekaville.com`;
  } else {
    logger.logException(new Error(`Could not create user friendly error message for ${originalMessage}`));
    return originalMessage;
  }
}
export function getTimeZoneOffsetAsString() {
  function pad(number: number, length: number) {
    let str = '' + number;

    while (str.length < length) {
      str = '0' + str;
    }

    return str;
  }

  const offset = new Date().getTimezoneOffset();
  const stringOffset =
    (offset < 0 ? '+' : '-') + // Note the reversed sign!
    pad(Math.floor(Math.abs(offset / 60)), 2) +
    pad(Math.abs(offset % 60), 2);
  return stringOffset;
}
export function loadBuyersGuide() {
  window.open(Documents.buyersGuide, '_blank');
}
export const getStudentSignUpUrl = () => {
  if (window.location.hostname === 'localhost') {
    return [`http://localhost:3008/signup`, `localhost:3008/signup`];
  } else if (import.meta.env.REACT_USE_DEV_API) {
    return [`https://test.mypeekaville.com/signup`, `test.mypeekaville.com/signup`];
  } else {
    return [`https://www.mypeekaville.com/signup`, `mypeekaville.com/signup`];
  }
};
export async function copyFormatted(html: string) {
  try {
    // Create a Blob from the HTML string
    const blob = new Blob([html], { type: 'text/html' });

    // Create a ClipboardItem from the Blob
    const clipboardItem = new ClipboardItem({ 'text/html': blob });

    // Write the ClipboardItem to the clipboard
    await navigator.clipboard.write([clipboardItem]);

    console.log('Formatted HTML copied to clipboard successfully!');
  } catch (err) {
    console.error('Failed to copy formatted HTML: ', err);
  }
}
export function getPageMaxWidth() {
  return 1536;
}
export function validatePassword(password: string) {
  const lowerBound = 9;
  if (password.length < lowerBound) return `Must be at least ${lowerBound} characters long`;
  if (!/[a-z]+/g.exec(password)) return 'Must have at least one lowercase letter';
  if (!/[A-Z]+/g.exec(password)) return 'Must have at least one uppercase letter';
  if (!/[0-9]+/g.exec(password)) return 'Must have at least one numeric digit';
  return undefined;
}
export function myMemoize<T extends (...args: any[]) => any>(
  cache: Record<string, ReturnType<T>>, // Cache for storing results of the memoized function
  method: T, // The function to be memoized
): (this: unknown, ...args: Parameters<T>) => Promise<ReturnType<T>> {
  return async function (this: unknown, ...args: Parameters<T>): Promise<ReturnType<T>> {
    const argsAsString = JSON.stringify(args);

    if (!cache[argsAsString]) {
      // Use `apply` to call the method with the correct `this` context and arguments
      cache[argsAsString] = await method.apply(this, args);
    }

    return cache[argsAsString];
  };
}
export function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

export function isTokenExpired(expiry: number, buffer = 0) {
  // if token is expired or within a buffer period of expiring
  const expired = Math.floor(Date.now() / 1000) - expiry >= -buffer;
  // console.debug(
  //   `isTokenExired `,
  //   expired,
  //   ' expires at  ',
  //   getReadableUnixTimeAnsi(expiry * 1000),
  //   ' current = ',
  //   Math.floor(Date.now() / 1000),
  //   ' expires = ',
  //   expiry
  // );
  return expired;
}

export const secondarySchoolLessonNumberLookup = (lessonName: string, isHighSchool?: boolean) => {
  const lessonNumbers: { [key: string]: number } = {
    introduction: 1,
    introduce: 1,
    Welcome: 1,
    learn: 2,
    storytelling: 2,
    'safe-spaces': 3,
    'read-whole-group': 3,
    norms: 3,
    'community-and-cooperation': 4,
    'read-self-or-small-group': 4,
    'read-independent': 4,
    'sel-simulation-activity': 4,
    empathize: 5,
    reflect: isHighSchool ? 6 : 5,
    connect: 6,
    discuss: 7,
    return: 7,
    apply: 8,
  };
  return lessonNumbers[lessonName];
};

export async function sendUserToRoleHomePage(
  u: UserProfileType,
  history: History,
  replacePath = false,
  // direct link like the logo in the header
  fromLoginOrDirectLink = true,
) {
  const urlFunction = replacePath ? history.replace : history.push;

  if (!u.sessionExpired) {
    if (u.type === 'Teacher' || u.type.includes('Admin')) {
      if (fromLoginOrDirectLink || history.location.pathname === '/') {
        if (u.licenseLevel.includes('ADMINISTRATOR')) {
          return urlFunction('/reports/school');
        }

        return urlFunction('/library');
      }

      return;
    }

    // user type is student
    if (isElementaryStudent(u) && !window.location.href.includes('play')) {
      const result = await getVisitorOriginBasedOnIp();
      const playUrl = getPlayUrl(result);
      window.location.href = playUrl;
      return;
    }

    // this wasn't an elementary student so this must be a
    // middle school student
    if (!history.location.pathname.includes('studentportal')) {
      const hasCheckedIn = getLocalStorage('isCheckInCompleteOrOptedOut');
      return hasCheckedIn === 'true' ? urlFunction('/studentportal/toDos') : urlFunction('/studentportal/checkin');
    }
  }
}

export function getPlayUrl(result: GetCountryOriginResult) {
  const urlStubMatrix: Record<string, string> = {
    uae: 'mena/play/index.htm',
    rotw: 'play/index.htm',
  };

  const location = result.country === 'United Arab Emirates' ? 'uae' : 'rotw';

  if (window.location.hostname === 'localhost') {
    return `https://test.peekapak.com/${urlStubMatrix[location]}`;
  } else {
    return `${window.location.protocol}//${window.location.hostname}/${urlStubMatrix[location]}`;
  }
}

export function getElementaryUnitDescriptions() {
  return [
    {
      unit: 'Pandemic-SEL',
      displayName: 'Pandemic SEL',
      gradeSpan: [-1, 0, 1, 2, 3, 4, 5],
      hasSpanishContent: true,
      content:
        'This unit welcomes students back to school under COVID-19 conditions. This unit will help classrooms establish and practice new routines, notice and name their concerns, and deal with changes that occur throughout the beginning of the year.',
    },
    {
      unit: 'Self-Regulation',
      content:
        'Self-regulation is a skill that helps students manage their behaviors and express emotions in constructive ways.',
      isTrialable: true,
      hasSpanishContent: true,
      gradeSpan: [-1, 0, 1, 2, 3, 4, 5],
      hasPeekavilleContent: true,
    },
    {
      unit: 'Respect',
      gradeSpan: [-1, 0, 1, 2, 3, 4, 5],
      hasSpanishContent: true,
      content:
        'Respect is a mindset that guides how we treat others. It teaches us to act in a manner that shows we care about the feelings and well-being of others, our environment, and ourselves.',
      hasPeekavilleContent: true,
    },
    {
      unit: 'Gratitude',
      hasSpanishContent: true,
      gradeSpan: [-1, 0, 1, 2, 3, 4, 5],
      content:
        'Gratitude is feeling thankful for what we have, recognizing our blessings (material or nonmaterial), and showing appreciation through acknowledging or returning a kind gesture.',

      hasPeekavilleContent: true,
    },
    {
      unit: 'Perseverance',
      hasSpanishContent: true,
      gradeSpan: [-1, 0, 1, 2, 3, 4, 5],
      content:
        'Perseverance is being steadily persistent and willing to continue on with a task or goal despite challenges or a delay in achieving success.',
      hasPeekavilleContent: true,
    },
    {
      unit: 'Empathy',
      hasSpanishContent: true,
      gradeSpan: [-1, 0, 1, 2, 3, 4, 5],
      content:
        'Empathy is understanding and sensing others; emotions and imagining what they are thinking and how they are feeling.',
      hasPeekavilleContent: true,
    },
    {
      unit: 'Teamwork',
      hasSpanishContent: true,
      gradeSpan: [-1, 0, 1, 2, 3, 4, 5],
      content: 'Teamwork means working cooperatively with others to achieve a shared goal.',
      hasPeekavilleContent: true,
    },
    {
      unit: 'Kindness',
      hasSpanishContent: true,
      gradeSpan: [-1, 0, 1, 2, 3, 4, 5],
      content:
        'Kindness is being considerate, caring, and respectful of others and acting in ways that will benefit others without expecting anything in return.',
      hasPeekavilleContent: true,
    },
    {
      unit: 'Honesty',
      hasSpanishContent: true,
      gradeSpan: [-1, 0, 1, 2, 3, 4, 5],
      content:
        'Honesty is being truthful in what we say and do. Honesty can be demonstrated through what we say, such as telling the truth, including only facts, and admitting when we are wrong.',
      hasPeekavilleContent: true,
    },
    {
      unit: 'Optimism',
      hasSpanishContent: true,
      gradeSpan: [-1, 0, 1, 2, 3, 4, 5],
      content:
        'Optimism is... a form of positive thinking that guides us in our belief about how successful we think our actions and outcomes will be.',
      hasPeekavilleContent: true,
    },
    {
      unit: 'Courage',
      hasSpanishContent: true,
      gradeSpan: [-1, 0, 1, 2, 3, 4, 5],
      content:
        "Courage is being brave, especially when faced with difficult situations and unfamiliar experiences. Courage is acting in accordance with one's beliefs, even if it's against what others may think.",
      hasPeekavilleContent: true,
    },
    {
      unit: 'Friendship',
      gradeSpan: [-1, 0, 1, 2],
      content:
        'Friendship is shared between two or more people who trust and care about each other and enjoy spending time together.',
    },
    {
      unit: 'Imagination',
      gradeSpan: [-1, 0, 1, 2],
      content: 'Imagination is the ability to be creative with your mind to picture things that aren’t there.',
    },
    {
      unit: 'Math-Anxiety',
      gradeSpan: [3, 4, 5],
      content: 'The stressful feeling that many of us get when doing math is called “math anxiety”.',
    },
    {
      unit: 'Celebrating-Differences',
      gradeSpan: [-1, 0, 1, 2, 3, 4, 5],
      content:
        'In this unit, students will learn to be curious about our differences and celebrate them. This introductory unit will open up the conversation with students to begin discussing race and equity by teaching foundational concepts including celebrating differences, leading with inquiry, and empathizing with others.',
    },
  ];
}

export function validateEmailInput(value: string) {
  if (!value) {
    return 'Please provide an email address';
  }

  if (!vm(value)) {
    return 'Must be in the correct form, e.g. joe@domain.com';
  }
  return '';
}

export function scrollIntoViewHelper(errors: FormikErrors<{}>) {
  const firstError = Object.keys(errors)[0];
  const el = document.querySelector(`[name='${firstError}']`);

  if (el) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
}

export function beautifyPhoneNumber(value: string) {
  let beautified = value.replace(/-/g, '');

  if (beautified.length > 3) {
    beautified = `${beautified.substring(0, 3)}-${beautified.substring(3)}`;
  }

  if (beautified.length > 7) {
    beautified = `${beautified.substring(0, 7)}-${beautified.substring(7)}`;
  }
  return beautified;
}

export function pickTextColorBasedOnBgColorAdvanced(bgColor: string, lightColor: string, darkColor: string) {
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? darkColor : lightColor;
}

export function checkForPathOnClassChange(pathname: string, currentGrade: number, newGrade: number) {
  const noChangeArray = ['/students', '/accountSettings', '/reports/District', '/reports/School', '/reports/Reseller'];

  if (currentGrade === newGrade) return '';
  if (noChangeArray.includes(pathname)) return '';

  if (currentGrade < 6 && newGrade > 5) {
    if (pathname.includes('/reports/Curriculum')) return '/reports/CurriculumSecondary';
    if (pathname.includes('/reports/Moodboard')) return '/reports/MoodboardSecondary';
    if (pathname.includes('/reports') || pathname.includes('/gallery')) return '/reports';
    return '/library';
  }

  if (currentGrade > 5 && newGrade < 6) {
    if (pathname.includes('/reports/CurriculumSecondary')) return '/reports/Curriculum';
    if (pathname.includes('/reports/MoodboardSecondary')) return '/reports/Moodboard';
    return '/library';
  }

  if ((currentGrade > 5 && newGrade > 5) || (currentGrade > 5 && newGrade > 5 && currentGrade === newGrade)) {
    if (pathname.includes('lessonPlan')) {
      const unitId = pathname.split('/')[2];
      const language = pathname.split('/')[3];
      const newUnitId = secondarySchoolUnitIdLookup(unitId, currentGrade, newGrade);
      if (newUnitId === null || newUnitId === '') return '/library';
      return pathname.replace(/\/lessonPlan\/(.*)\/(.*)\/(.*)/, `/lessonPlan/${newUnitId}/${language}/${newGrade}`);
    }
    return '';
  }

  if (currentGrade < 3 && newGrade > 2) {
    return pathname.includes('Problem-Solving') ? '/library' : '';
  }

  if (currentGrade > 2 && newGrade < 3) {
    return pathname.includes('Math-Anxiety') ? '/library' : '';
  }

  if (currentGrade > 5 && newGrade < 6) {
    if (pathname.includes('/reports/CurriculumSecondary')) return '/reports/Curriculum';
    if (pathname.includes('/reports/MoodboardSecondary')) return '/reports/Moodboard';
    return '/library';
  }

  return '';
}

const secondarySchoolUnitIdLookup = (unitId: string, currentGrade: number, newGrade: number) => {
  if (currentGrade > 8 && newGrade < 9) return '';
  if (currentGrade < 9 && newGrade > 8) return '';
  return unitId;
};

//format date to YYYY-MM-DD or MM/DD/YYYY
export const formatDate = (date: Date, format?: string) => {
  const d = new Date(date);
  const year = d.getFullYear();
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return format === 'dash'
    ? [year, month, day].join('-')
    : format === 'slash'
      ? [month, day, year].join('/')
      : [year, month, day].join('-');
};

export const getJobStatus = async (jobId: string, stateSetter: (arg0: number) => void) => {
  function isJobPending(job: { status: string }) {
    return job.status !== 'Done' && job.status !== 'Error';
  }
  function processPendingJob(job: { data: string }) {
    if (job.data) {
      const data = JSON.parse(job.data);
      stateSetter(data.completionPercent);
    }
  }
  function yieldAndWait() {
    return new Promise<void>((resolve) => {
      setTimeout(() => resolve(), 1000);
    });
  }
  let job: { status: string; data: string } = await getJob(jobId);
  try {
    while (isJobPending(job)) {
      processPendingJob(job);
      await yieldAndWait();
      job = await getJob(jobId);
    }
    return job.data;
  } catch (e) {
    console.error('Error while getting job status: ', e);
  }
};

export const getUnitTitleForDisplay = (unitTitle: string) => {
  const unitTitleGForDisplay = unitTitle.replace('Mena', '');
  return unitTitleGForDisplay === 'Self-Regulation' || unitTitleGForDisplay === 'Problem-Solving'
    ? unitTitleGForDisplay
    : unitTitleGForDisplay.replace('-', ' ');
};

// This functions gets the path to the resources folder based on the grade
// starting off of getPublicMediaRoot()
export const getPublicMediaResourcesPath = (numberGrade: string) => {
  const grade = parseInt(numberGrade, 10);
  let path = 'resourcesMiddleSchool/';
  if (grade > 8) {
    path = 'resourcesHighSchool/';
  } else if (grade < 6) {
    path = 'resources/';
  }
  return path;
};

export const shuffleArray = <T>(array: T[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const isCheckInModule = (moduleId: string) => {
  const msCheckInModuleId = 'welcome-check-in-tool-student';
  const hsCheckInModuleId = 'hswelcome-slides-workshops-check-in-tool-student';
  return moduleId === msCheckInModuleId || moduleId === hsCheckInModuleId;
};

export const getPluralOrSingular = (value: number, unit: string) => (value === 1 ? unit : `${unit}s`);

export const formatLessonDurationPluralOrSingular = (minutes: number) => {
  const absTotal = Math.abs(minutes);
  const mins = absTotal % 60;
  const hours = Math.floor(absTotal / 60);
  const days = Math.floor(hours / 24);
  const hourss = hours % 24;

  const daysForDisplay = days ? `${days} ${getPluralOrSingular(days, 'Day')} ` : '';
  const hoursForDisplay = hourss ? `${hourss} ${getPluralOrSingular(hourss, 'Hour')} ` : '';
  const minsForDisplay = mins ? `${mins} Minutes` : '';

  return `${daysForDisplay}${hoursForDisplay}${minsForDisplay}`;
};

export const formatLessonDurationSingular = (minutes: number) => {
  const absTotal = Math.abs(minutes);
  const mins = absTotal % 60;
  const hours = Math.floor(absTotal / 60);
  const days = Math.floor(hours / 24);
  const hourss = hours % 24;

  const daysForDisplay = days ? `${days} Day` : '';
  const hoursForDisplay = hourss ? `${hourss} Hour` : '';
  const minsForDisplay = mins ? `${mins} Minute` : '';

  return `${daysForDisplay}${hoursForDisplay}${minsForDisplay}`;
};

export const getModuleDuration = (module: Module) => {
  if (module.duration && !module.durationMin && !module.durationMax) {
    const duration = formatLessonDurationPluralOrSingular(module.duration);
    return duration;
  } else if (!module.duration && module.durationMin && module.durationMax) {
    const min = formatLessonDurationSingular(module.durationMin);
    const max = formatLessonDurationSingular(module.durationMax);
    const minStrArr = min.split(' ');
    const maxStrArr = max.split(' ');
    if (minStrArr[1] === maxStrArr[1]) {
      const suffix = maxStrArr.slice(1).join(' ');
      return `${minStrArr[0]} - ${maxStrArr[0]} ${suffix}s`;
    } else {
      const minForDisplay = formatLessonDurationPluralOrSingular(module.durationMin);
      const maxForDisplay = formatLessonDurationPluralOrSingular(module.durationMax);
      return `${minForDisplay} - ${maxForDisplay}`;
    }
  } else if (!module.duration && module.durationMin && !module.durationMax) {
    const min = formatLessonDurationSingular(module.durationMin);
    const minStrArr = min.split(' ');
    return `${minStrArr[0]}+ ${minStrArr[1]}s`;
  } else {
    return '';
  }
};

export const isUrl = (inputString: string): boolean => {
  const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[^\s]*)?$/i;
  if (urlRegex.test(inputString)) {
    return true;
  }

  return false;
};

export function isString(x: unknown) {
  return typeof x === 'string' || x instanceof String;
}

export function isNumber(x: unknown) {
  return !isNaN(parseFloat(x as string)) && !isNaN((x as number) - 0);
}

export const logDebugLevel = (message: string, ...rest: unknown[]) => {
  if (import.meta.env.MODE === 'development') {
    console.info(`%c${message}`, 'background: #222; color: #bada55', ...rest);
  }
};

export function getGradeAsNumber(inputGrade: unknown): number {
  if (inputGrade !== undefined) {
    if (isString(inputGrade)) {
      const asString = inputGrade as unknown as string;
      if (userInputGradeToNumberMap.has(asString)) {
        // we can assert because we already checked that it's in the map
        return userInputGradeToNumberMap.get(asString)!;
      }
      const test = parseInt(asString, 10);
      return isNaN(test) ? -1 : test;
    } else if (isNumber(inputGrade)) {
      return inputGrade as number;
    }
  }
  return -1;
}

export const userInputGradeToNumberMap: Map<string, number> = new Map();
userInputGradeToNumberMap.set('Pre-Kindergarten', -1);
userInputGradeToNumberMap.set('Prekindergarten', -1);
userInputGradeToNumberMap.set('PreKindergarten', -1);
userInputGradeToNumberMap.set('Pre', -1);
userInputGradeToNumberMap.set('PreK', -1);
userInputGradeToNumberMap.set('prek', -1);
userInputGradeToNumberMap.set('PK', -1);
userInputGradeToNumberMap.set('pk', -1);
userInputGradeToNumberMap.set('PS', -1);
userInputGradeToNumberMap.set('Prek', -1);
userInputGradeToNumberMap.set('Pre-K', -1);
userInputGradeToNumberMap.set('EC', -1);
userInputGradeToNumberMap.set('TK', -1);
userInputGradeToNumberMap.set('Kindergarten', 0);
userInputGradeToNumberMap.set('kindergarten', 0);
userInputGradeToNumberMap.set('-1', -1);
userInputGradeToNumberMap.set('0', 0);
userInputGradeToNumberMap.set('00', 0);
userInputGradeToNumberMap.set('K', 0);
userInputGradeToNumberMap.set('K-1', 0);
userInputGradeToNumberMap.set('k', 0);
userInputGradeToNumberMap.set('kg', 0);
userInputGradeToNumberMap.set('KG', 0);
userInputGradeToNumberMap.set('KF', 0);
userInputGradeToNumberMap.set('kk', 0);
userInputGradeToNumberMap.set('K-2', 0);
userInputGradeToNumberMap.set('All', 0);
userInputGradeToNumberMap.set('counselor', 0);
userInputGradeToNumberMap.set('1st Grade', 1);
userInputGradeToNumberMap.set('1', 1);
userInputGradeToNumberMap.set('01', 1);
userInputGradeToNumberMap.set('1st', 1);
userInputGradeToNumberMap.set('First', 1);
userInputGradeToNumberMap.set('2nd Grade', 2);
userInputGradeToNumberMap.set('02', 2);
userInputGradeToNumberMap.set('2', 2);
userInputGradeToNumberMap.set('2nd', 2);
userInputGradeToNumberMap.set('Second', 2);
userInputGradeToNumberMap.set('3rd Grade', 3);
userInputGradeToNumberMap.set('3rd', 3);
userInputGradeToNumberMap.set('3', 3);
userInputGradeToNumberMap.set('3F', 3);
userInputGradeToNumberMap.set('03', 3);
userInputGradeToNumberMap.set('Third', 3);
userInputGradeToNumberMap.set('4th Grade', 4);
userInputGradeToNumberMap.set('4', 4);
userInputGradeToNumberMap.set('4F', 4);
userInputGradeToNumberMap.set('04', 4);
userInputGradeToNumberMap.set('5th Grade', 5);
userInputGradeToNumberMap.set('5', 5);
userInputGradeToNumberMap.set('5F', 5);
userInputGradeToNumberMap.set('05', 5);
userInputGradeToNumberMap.set('6th Grade', 6);
userInputGradeToNumberMap.set('6', 6);
userInputGradeToNumberMap.set('7th Grade', 7);
userInputGradeToNumberMap.set('7th', 7);
userInputGradeToNumberMap.set('7', 7);
userInputGradeToNumberMap.set('07', 7);
userInputGradeToNumberMap.set('8th Grade', 8);
userInputGradeToNumberMap.set('8th', 8);
userInputGradeToNumberMap.set('8', 8);
userInputGradeToNumberMap.set('08', 8);
userInputGradeToNumberMap.set('9', 9);
userInputGradeToNumberMap.set('10', 10);
userInputGradeToNumberMap.set('11', 11);
userInputGradeToNumberMap.set('12', 12);
