import { useMediaQuery } from 'react-responsive';

interface ChildProps {
  children: JSX.Element;
}

export const Desktop = ({ children }: ChildProps): JSX.Element | null => {
  const isDesktop = useMediaQuery({ minWidth: 1280 });
  return isDesktop ? children : null;
};
export const Tablet = ({ children }: ChildProps): JSX.Element | null => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 }); // up to MDPI
  return isTablet ? children : null;
};
export const Default = ({ children }: ChildProps): JSX.Element | null => {
  const isNotMobile = useMediaQuery({ maxWidth: 767 }); // smaller than iPad
  return isNotMobile ? children : null;
};
export const IPadAndBigger = ({ children }: ChildProps): JSX.Element | null => {
  const isIpadOrBigger = useMediaQuery({ minWidth: 768 }); // iPad and up
  return isIpadOrBigger ? children : null;
};
