import { ReactNode } from 'react';
import cx from 'classnames';
import styles from './SectionHeader.module.scss';

interface Props {
  alignLeft?: boolean;
  className?: string;
  preTitle?: string;
  title?: string;
  copy?: string;
  center?: boolean;
  small?: boolean;
  extraSmall?: boolean;
  children?: ReactNode;
}

const SectionHeader = ({
  alignLeft,
  className,
  preTitle,
  title,
  copy,
  center,
  small,
  extraSmall,
  children,
}: Props): JSX.Element => {
  return (
    <div
      className={cx(styles.sectionHeader, {
        [`${className}`]: className,
        [`${styles.alignLeft}`]: alignLeft,
        [`${styles.center}`]: center,
        [`${styles.small}`]: small,
        [`${styles.extraSmall}`]: extraSmall,
      })}
    >
      {preTitle && <h3 className={styles.preTitle}>{preTitle}</h3>}
      {title && <h1 className={styles.title}>{title}</h1>}
      {copy && <p className={styles.copy}>{copy}</p>}
      {children}
    </div>
  );
};

export default SectionHeader;
